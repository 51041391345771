import React from 'react';


class LogisticsGridItem extends React.Component {

    constructor(props) {
        super();
    }



    render() {

        return (
            <div className="logisticsGridItem">
                <h3 className="OptionTitle">{this.props.title}</h3> 
                <ul> 
                    {this.props.content.map(bullet => (<li>{bullet}</li>))}
                </ul> 
            </div>
        );
    }
}


export default LogisticsGridItem;