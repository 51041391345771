import logo from './../logo.svg';
import './../App.css';
import DesktopNavbar from './DesktopNavbar.js';
import About from './About.js'; 
import App from '../App.js';
import BookNow from './BookNow.js';
import AllTours from './AllTours.js';
import CruiseTours from './CruiseTours.js';

import ContactForm from './ContactForm.js'; 
import '../Styles/Contact.css'; 

const contactFields = [
    { type: "text", title: "First Name", required: true, placeholder: "Eiluv", id: "" },
    { type: "text", title: "Last Name", required: true, placeholder: "Belize", id: "" },
    { type: "text", title: "Email Address", required: true, placeholder: "jdoe@example.com", id: "" },
]; 


function Contact({tours}) {

    return (
        <div className="contactBody">
            <div className="homeTitle">Contact Placencia Action Tours</div>
            <ContactForm contactFields={contactFields} tours={tours}/> 
        </div>
    );
}

export default Contact;
