
function FollowUsList({ followUs }) {
    return (
        <div className="followUsList">
            <div className="followUsTitle"> Follow us on: </div>
            {
                followUs.map(icon => (
                    <a href={icon.url} alt={icon.alt}>
                        {icon.imgComponent()}
                    </a>))
            }
        </div>
    );
}

export default FollowUsList;