import logo from './../logo.svg';
import './../App.css';
import '../Styles/Contact.css'; 
import DesktopNavbar from './DesktopNavbar.js';
import About from './About.js'; 
import App from '../App.js';
import AllTours from './AllTours.js';
import CruiseTours from './CruiseTours.js';
import Contact from './Contact.js';

import React, { Component } from 'react';              //Use of class-based react components
import * as emailjs from 'emailjs-com';                //Use of emailjs to avoid smpt server 
import ReactDOMServer from 'react-dom/server'; 

import BookingTransportationGrid from './BookingTransportationGrid.js'; 
import CheckboxTours from './CheckboxTours.js';
import MessageForUs from './MessageForUs.js';
import SubmitButton from './SubmitButton.js';
import TourDate from './TourDate.js';
import TextField from './TextField.js';
import TotalCost from './TotalCost.js'; 
import TransportationOptions from './TransportationOptions.js'; 
import PPBtn from './PPBtn.js'; 


const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const placenciaEmail = "info@southernbelizetours.com";


class BookNow extends React.Component {

    constructor(props) {
        emailjs.init("user_HL1bjtoOHSEOpRUaSXa55");
        super();
        this.contactFields = props.contactFields;
        this.tours = props.tours;

        //this.history = useHistory(); 

        /*Status requirements:
         * message field needs to be at least 17 chars long
         * first name has to be at least 1 character long 
         * last name has to be at least 1 character long
         * email has to pass regex test 
         * They have to select at least one tour 
         */
        this.state = {
            firstNameContent: "", firstNameStatus: false,
            lastNameContent: "", lastNameStatus: false,
            emailContent: "", emailStatus: false,
            selectContent: "2",
            numAdults: "2", 
            numTinies: 0, tinyMessage: false, 
            toursSelected: props.selectedTours ? props.selectedTours.map(tour=>props.tours.find(o=>o.name ===tour)): [], tourStatus: false,
            dateContent: "", dateStatus: true,
            totalPrice: 0, basePrice: 0, basePriceChild: 0,
            hotel: true,
            hotelContent: "", hotelStatus: false,
            bundleName: ""
        };

        this.messageForUsCallback = this.messageForUsCallback.bind(this);
        this.firstNameCallback = this.firstNameCallback.bind(this);
        this.lastNameCallback = this.lastNameCallback.bind(this);
        this.emailCallback = this.emailCallback.bind(this);
        this.tourCallback = this.tourCallback.bind(this);
        this.dateInputChangeCallback = this.dateInputChangeCallback.bind(this);
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.hotelCallback = this.hotelCallback.bind(this); 
        this.transportationCallback = this.transportationCallback.bind(this); 
        this.bundleCallback = this.bundleCallback.bind(this); 
    }

    bundleCallback(str){
        this.setState({bundleName: str}); 
    }

    /*Returns the status of the state variables for all of the form contents*/
    isValid() {
        return this.state.firstNameStatus
            && this.state.lastNameStatus
            && this.state.emailStatus
            && this.state.tourStatus
            && this.state.dateStatus
            && (this.state.hotel ? this.state.hotelStatus : true);
    }


    dateInputChangeCallback(textValue, status) {
        this.setState({ dateContent: textValue, dateStatus: status });
    }

    //Helper function that makes list of tours into a string
    makeTourString(arr) {
        let ret = "";
        for (let i = 0; i < arr.length; ++i) {
            ret += arr[i].name + ",";
        }
        return ret;
    }

    produceOperatorHtmlEmail(ppID){
        let fullTemplate =
        <div style={{fontWeight:'400', fontSize: '1.2rem', color: 'black'}}>
            <div style = {{width: '100%', height: '5rem', backgroundColor:"#4da5fd"}}>
                    <img style = {{display: 'flex', paddingLeft: 'auto', paddingRight: 'auto', position: "absolute", top: "5px", left: "5px"}} src = "https://www.southernbelizetours.com/static/media/companyLogoDesktop.0324ccd1.png"/> 
            </div> 
            <p>Julian and PAT Staff,</p>
            <p style = {{marginBottom: '2rem'}}>This is an automated email regarding a booking confirmation for a tour. Below is the email sent to the customer - it should have everything necessary for facilitating their adventure.</p> 
            <p style={{color: 'black', padding: "0px 2rem"}}>Hello {this.state.firstNameContent + " " + this.state.lastNameContent},</p>
            <p style={{color: 'black', padding: "0px 2rem"}}>This is an automated message confirming that our guides have received your booking! <span style={{fontWeight: '600'}}>Expect one of Placencia Action Tours' (Southern Belize Tours) professional guides to contact you shortly to provide details on your reservation.</span> Please verify your contact information in the below details is accurate so that our guides can reach out to you. Please email info@southernbelizetours.com to correct any of the details in the reservation below.</p> 
            <div style={{
                         borderStyle: 'solid',
                         borderWidth: '2px', 
                         borderRadius: '5px',
                         borderColor: '#c83d3a',
                         padding: '10px',}}>
                    <div style={{width: '100%',
                         textAlign: 'center',
                         verticalAlign: 'middle',
                         color:'#f7c400f7', 
                         fontSize: '1.8rem',
                         marginBottom: '4%',
                         fontWeight: '600' }}>
                        Reservation Details 
                    </div>
                    <div style={{width: '100%',
                                 marginBottom: '1%'}}> 
                        <div style={{width:'30%', verticalAlign: 'top',
                                    marginRight: '2%',
                                    display: 'inline-block'}}>
                            <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Full Name:</div>
                            <div style={{color: '#4da5fd'}}>{this.state.firstNameContent + " " + this.state.lastNameContent}</div>
                        </div> 
                        <div style={{width:'30%', verticalAlign: 'top',
                                    marginRight: '2%',
                                    display: 'inline-block'}}>
                            <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Tour Date:</div>
                            <div style={{color: '#4da5fd'}}>{this.state.dateContent}</div>
                        </div>
                        <div style={{width:'30%', verticalAlign: 'top',
                                    marginRight: '2%',
                                    display: 'inline-block'}}>
                            <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Party Size:</div>
                            <div style={{color: '#4da5fd', fontWeight: '400', fontSize: '1.2rem'}}>{this.state.selectContent} people over 6 years old</div>
                            <div style={{color: '#4da5fd', fontWeight: '400', fontSize: '1.2rem'}}>{this.state.numTinies} children 5 ages and under</div>
                        </div>
                    </div> 
                    <div style={{width: '100%',
                                 marginBottom: '1%'}}> 
                        <div style={{width:'30%', verticalAlign: 'top',
                                    marginRight: '2%',
                                    display: 'inline-block'}}>
                            <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Email:</div>
                            <div style={{color: '#4da5fd'}}>{this.state.emailContent}</div>
                        </div>
                        <div style={{width:'30%', verticalAlign: 'top',
                                    marginRight: '2%',
                                    display: 'inline-block'}}>
                                                            <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>
                                    Method of Arriving in Placencia: 
                                </div> 
                                <div style={{color: '#4da5fd', fontWeight: '400', fontSize: '1.2rem'}}>
                                        {this.state.hotel==1 ? <span>
                                                                  A Hotel in Placencia Area
                                                              </span>
                                        :this.state.hotel==2 ? <span>
                                                                  Norweigan Cruiselines tendering to Harvest Caye, taking the ferry to Placencia Docks
                                                                  <span style={{fontSize: '.7rem', verticalAlign: 'top', color: '#c83d3a'}}>1</span>
                                                                </span>
                                        :null}
                                </div>
                        </div>
                        <div style={{width:'30%', verticalAlign: 'top',
                                    marginRight: '2%',
                                    display: 'inline-block'}}>
                            <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>{this.state.hotel==1? "Hotel Name" : this.state.hotel==2? "Cruise Name" : "Caye Name"}:</div>
                            <div style={{color: '#4da5fd'}}>{this.state.hotelContent}</div>
                        </div>
                    </div>
                    <div style={{width: '100%',
                                 marginBottom: '1%'}}> 
                        <div style={{width:'30%', verticalAlign: 'top',
                                    marginRight: '2%',
                                    display: 'inline-block'}}>
                            <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Reservation Fee Paid via PayPal:</div>
                            <div style={{color: '#4da5fd'}}>${this.state.totalPrice * .1} USD</div>
                        </div>
                        <div style={{width:'30%', verticalAlign: 'top',
                                    marginRight: '2%',
                                    display: 'inline-block'}}>
                            <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Remaining Tour Cost Owed to Guides:</div>
                            <div style={{color: '#4da5fd'}}>${this.state.totalPrice * .9} USD or ${this.state.totalPrice * 1.8} BZE (Both currencies are accepted)</div>
                        </div>
                        <div style={{width:'30%', verticalAlign: 'top',
                                    marginRight: '2%',
                                    display: 'inline-block'}}>
                            <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Purchase ID:</div>
                            <div style={{color: '#4da5fd'}}>{ppID!=null ? ppID : "processing ID"}</div>
                        </div> 
                    </div> 
                <div  style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Tours Booked: </div>
                <ol>
                {
                        this.state.toursSelected.map(selectedTour=>
                            (selectedTour.text==="Cave Tubing" ? <li style={{color: 'black', marginBottom: '8px'}}>{selectedTour.text} <span style={{fontWeight: '600'}}><a style={{fontWeight: "500", margin: "0 1%", textDecoration: "none", cursor: "pointer", color: 'black', borderStyle: 'solid', borderWidth: '2px', borderColor: '#4da5fd', color: '#4da5fd', borderRadius: '5px', padding: '2px 5px'}}href={"https://southernbelizetours.com" + selectedTour.url}>View Tour Details</a>(NOTE: Please review our <a href='cave-tubing.net/safety'>safety</a> guidelines to prepare for cave tubing)</span></li> : 
                             selectedTour.text==="Rainforest Ziplining" ? <li style={{color: 'black', marginBottom: '8px'}}>{selectedTour.text} <span style={{fontWeight: '600'}}><a style={{fontWeight: "500", margin: "0 1%", textDecoration: "none", cursor: "pointer", color: 'black', borderStyle: 'solid', borderWidth: '2px', borderColor: '#4da5fd', color: '#4da5fd', borderRadius: '5px', padding: '2px 5px'}}href={"https://southernbelizetours.com" + selectedTour.url}>View Tour Details</a>(NOTE: Maximum ziplining weight is 300lbs{this.state.numTinies > 0 ? ". You have also specified bringing children 5 and under. The minimum age for ziplining is 4 years old": null})</span></li>
                                                                :<li style={{color: 'black', marginBottom: '8px'}}>{selectedTour.text} <a style={{fontWeight: '500', margin: "0 1%", textDecoration: "none", cursor: "pointer", color: 'black', borderStyle: 'solid', borderWidth: '2px', borderColor: '#4da5fd', color: '#4da5fd', borderRadius: '5px', padding: '2px 5px'}}href={"https://southernbelizetours.com" + selectedTour.url}>View Tour Details</a></li>))
                    }
                </ol>
                {this.state.bundleName==="" ? null : 
                    <div style={{color: 'black', width: '100%', marginBottom: '1%'}}>
                        You saved by bundling {this.state.bundleName} for a day filled with adventure! 
                    </div> 
                }
                { this.state.hotel==2 || this.state.hotel==3 || this.state.hotel==1? 
                    <div style={{ width: '100%', marginBottom: '1%'}}>
                        <div style = {{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Notes: </div> 
                        { this.state.hotel==2? <p style={{fontWeight: '500', color: 'black', margin: '5px 0px'}}>
                <span style={{fontSize: '.7rem', verticalAlign: 'top', color: '#c83d3a'}}>1</span>
                You have specified embarking from a cruise line. Please take the <span style={{fontWeight: '600', fontSize: '1.3rem', color: '#c83d3a'}}>EARLIEST POSSIBLE TENDER </span> 
                (boat transporting you between your cruise ship and Harvest Caye) in order to have the most time during your tour experience.
                The earliest cruise tender from the cruise ship will come to Harvest Caye. From here, please take the Harvest Caye Ferry to Placencia Docks.
                Once your party arrives, you will be met with a sign that says "Placencia Action Tours"
                From there, you will board your tour vehicle for your adventure. Your party will be dropped off in the evening in time for the last tender back to the 
                cruise ship from Harvest Caye. There is no additional cost for cruise guests tendering to Harvest Caye, however the prices for the round trip on the ferry between Harvest Caye and Placencia docks are not included in this booking. The cruise ships dock approximately 6 miles off the
                mainland. Please note that  you will have plenty of time to enjoy your tour adventure and get back safely to your cruise ship.</p>
             :this.state.hotel==1 ? <p style={{fontWeight: '500', color: 'black'}}>
                 You have specified that you are staying at a Placencia Hotel. Please verify that the hotel name you specified - {this.state.hotelContent} - is correct. If you need to edit any of your specified details, please email us at info@southernbelizetours.com. Your guides will contact you to arrange the logistics of picking you up from your hotel for your tour. 
             </p>
             : null }
                    </div> 
                : null}
            </div>
            <p style = {{color: 'black'}}>If you have any questions before your tour, please feel free to personally contact us at info@southernbelizetours.com</p>
            <p style = {{color: 'black'}}>Also, do not forget to look at our <a href="https://southernbelizetours.com/FAQ">Frequenty Asked Questions</a> page and you may find what you have been looking for!</p> 
        </div>; 
    return ReactDOMServer.renderToStaticMarkup(fullTemplate); 
    }

    produceCustomerHtmlEmail(ppID){
        let fullTemplate =
            <div style={{fontWeight:'400', fontSize: '1.2rem', color: 'black'}}>
                <div style = {{width: '100%', height: '5rem', backgroundColor:"#4da5fd"}}>
                        <img style = {{display: 'flex', paddingLeft: 'auto', paddingRight: 'auto', position: "absolute", top: "5px", left: "5px"}} src = "https://www.southernbelizetours.com/static/media/companyLogoDesktop.0324ccd1.png"/> 
                </div> 
                <p style={{color: 'black', padding: "0px 2rem"}}>Hello {this.state.firstNameContent + " " + this.state.lastNameContent},</p>
                <p style={{color: 'black', padding: "0px 2rem"}}>This is an automated message confirming that our guides have received your booking! <span style={{fontWeight: '600'}}>Expect one of Placencia Action Tours (Southern Belize Tours') professional guides to contact you shortly to provide details on your reservation.</span> Please verify your contact information in the below details is accurate so that our guides can reach out to you. Please email info@southernbelizetours.com to correct any of the details in the reservation below.</p> 
                <div style={{
                             borderStyle: 'solid',
                             borderWidth: '2px', 
                             borderRadius: '5px',
                             borderColor: '#c83d3a',
                             padding: '10px',}}>
                        <div style={{width: '100%',
                             textAlign: 'center',
                             verticalAlign: 'middle',
                             color:'#f7c400f7', 
                             fontSize: '1.8rem',
                             marginBottom: '4%',
                             fontWeight: '600' }}>
                            Reservation Details 
                        </div>
                        <div style={{width: '100%',
                                     marginBottom: '1%'}}> 
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Full Name:</div>
                                <div style={{color: '#4da5fd'}}>{this.state.firstNameContent + " " + this.state.lastNameContent}</div>
                            </div> 
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Tour Date:</div>
                                <div style={{color: '#4da5fd'}}>{this.state.dateContent}</div>
                            </div>
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Party Size:</div>
                                <div style={{color: '#4da5fd', fontWeight: '400', fontSize: '1.2rem'}}>{this.state.selectContent} people over 6 years old</div>
                                <div style={{color: '#4da5fd', fontWeight: '400', fontSize: '1.2rem'}}>{this.state.numTinies!=null ? this.state.numTinies : "0"} children 5 ages and under</div>
                            </div>
                        </div> 
                        <div style={{width: '100%',
                                     marginBottom: '1%'}}> 
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Email:</div>
                                <div style={{color: '#4da5fd'}}>{this.state.emailContent}</div>
                            </div>
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>
                                    Method of Arriving in Placencia: 
                                </div> 
                                <div style={{color: '#4da5fd', fontWeight: '400', fontSize: '1.2rem'}}>
                                        {this.state.hotel==1 ? <span>
                                                                  A Hotel in Placencia Area
                                                              </span>
                                        :this.state.hotel==2 ? <span>
                                                                  Norweigan Cruiselines tendering to Harvest Caye, taking the ferry to Placencia Docks
                                                                  <span style={{fontSize: '.7rem', verticalAlign: 'top', color: '#c83d3a'}}>1</span>
                                                                </span>
                                        :null}
                                </div>
                            </div>
                            { this.state.hotel === 3 ? null : 
                                <div style={{width:'30%', verticalAlign: 'top',
                                            marginRight: '2%',
                                            display: 'inline-block'}}>
                                    <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>{this.state.hotel==1? "Hotel Name" : this.state.hotel==2? "Cruise Name" : ""}:</div>
                                    <div style={{color: '#4da5fd'}}>{this.state.hotelContent}</div>
                                </div>
                            }
                        </div>
                        <div style={{width: '100%',
                                     marginBottom: '1%'}}> 
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Reservation Fee Paid via PayPal:</div>
                                <div style={{color: '#4da5fd'}}>${this.state.totalPrice * .1} USD</div>
                            </div>
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Remaining Tour Cost Owed to Guides:</div>
                                <div style={{color: '#4da5fd'}}>${this.state.totalPrice * .9} USD or ${this.state.totalPrice * 1.8} BZE (Both currencies are accepted)</div>
                            </div>
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Purchase ID:</div>
                                <div style={{color: '#4da5fd'}}>{ppID!=null ? ppID : "processing ID"}</div>
                            </div> 
                        </div> 
                    <div  style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Tours Booked: </div>
                    <ol>
                    {
                        this.state.toursSelected.map(selectedTour=>
                            (selectedTour.text==="Cave Tubing" ? <li style={{color: 'black', marginBottom: '8px'}}>{selectedTour.text} <span style={{fontWeight: '600'}}><a style={{fontWeight: "500", margin: "0 1%", textDecoration: "none", cursor: "pointer", color: 'black', borderStyle: 'solid', borderWidth: '2px', borderColor: '#4da5fd', color: '#4da5fd', borderRadius: '5px', padding: '2px 5px'}}href={"https://southernbelizetours.com" + selectedTour.url}>View Tour Details</a>(NOTE: Please review our <a href='cave-tubing.net/safety'>safety</a> guidelines to prepare for cave tubing)</span></li> : 
                             selectedTour.text==="Rainforest Ziplining" ? <li style={{color: 'black', marginBottom: '8px'}}>{selectedTour.text} <span style={{fontWeight: '600'}}><a style={{fontWeight: "500", margin: "0 1%", textDecoration: "none", cursor: "pointer", color: 'black', borderStyle: 'solid', borderWidth: '2px', borderColor: '#4da5fd', color: '#4da5fd', borderRadius: '5px', padding: '2px 5px'}}href={"https://southernbelizetours.com" + selectedTour.url}>View Tour Details</a>(NOTE: Maximum ziplining weight is 300lbs{this.state.numTinies > 0 ? ". You have also specified bringing children 5 and under. The minimum age for ziplining is 4 years old": null})</span></li>
                                                                :<li style={{color: 'black', marginBottom: '8px'}}>{selectedTour.text} <a style={{fontWeight: '500', margin: "0 1%", textDecoration: "none", cursor: "pointer", color: 'black', borderStyle: 'solid', borderWidth: '2px', borderColor: '#4da5fd', color: '#4da5fd', borderRadius: '5px', padding: '2px 5px'}}href={"https://southernbelizetours.com" + selectedTour.url}>View Tour Details</a></li>))
                    }
                    </ol>
                    {this.state.bundleName==="" ? null : 
                        <div style={{color: 'black', width: '100%', marginBottom: '1%'}}>
                            You saved by bundling {this.state.bundleName} for a day filled with adventure! 
                        </div> 
                    }
                    { this.state.hotel==2 || this.state.hotel==3 || this.state.hotel==1? 
                                            <div style={{ width: '100%', marginBottom: '1%'}}>
                                            <div style = {{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Notes: </div> 
                                            { this.state.hotel==2? <p style={{fontWeight: '500', color: 'black', margin: '5px 0px'}}>
                                    <span style={{fontSize: '.7rem', verticalAlign: 'top', color: '#c83d3a'}}>1</span>
                                    You have specified embarking from a cruise line. Please take the <span style={{fontWeight: '600', fontSize: '1.3rem', color: '#c83d3a'}}>EARLIEST POSSIBLE TENDER </span> 
                                    (boat transporting you between your cruise ship and Harvest Caye) in order to have the most time during your tour experience.
                                    The earliest cruise tender from the cruise ship will come to Harvest Caye. From here, please take the Harvest Caye Ferry to Placencia Docks.
                                    Once your party arrives, you will be met with a sign that says "Placencia Action Tours"
                                    From there, you will board your tour vehicle for your adventure. Your party will be dropped off in the evening in time for the last tender back to the 
                                    cruise ship from Harvest Caye. There is no additional cost for cruise guests tendering to Harvest Caye, however the prices for the round trip on the ferry between Harvest Caye and Placencia docks are not included in this booking. The cruise ships dock approximately 6 miles off the
                                    mainland. Please note that  you will have plenty of time to enjoy your tour adventure and get back safely to your cruise ship.</p>
                                 :this.state.hotel==1 ? <p style={{fontWeight: '500', color: 'black'}}>
                                     You have specified that you are staying at a Placencia Hotel. Please verify that the hotel name you specified - {this.state.hotelContent} - is correct. If you need to edit any of your specified details, please email us at info@southernbelizetours.com. Your guides will contact you to arrange the logistics of picking you up from your hotel for your tour. 
                                 </p>
                                 : null }
                                        </div> 
                                    : null}
                </div>
                <p style = {{color: 'black'}}>If you have any questions before your tour, please feel free to personally contact us at info@southernbelizetours.com</p>
                <p style = {{color: 'black'}}>Also, do not forget to look at our <a href="https://southernbelizetours.com/FAQ">Frequenty Asked Questions</a> page and you may find what you have been looking for!</p> 
            </div>; 
        return ReactDOMServer.renderToStaticMarkup(fullTemplate); 
    }

    /*Checks to make sure the form is happy, sends the email, and looks into the status of the email*/
    handleSubmitClick(ppData) {
        if (this.isValid()) {
            let customerEmail = this.produceCustomerHtmlEmail(ppData.orderID);//(ppData.id); 
            let operatorEmail = this.produceOperatorHtmlEmail(ppData.orderID);
           // let guideEmail = this.produceGuideHtmlEmail(); 
            /*let location = this.state.hotelContent;
            if (location === "") location = "Placencia Docks from the Harvest Caye Ferry"; 
            let tourString = this.makeTourString(this.state.toursSelected); */ 
            //console.log(customerEmail); 

            //Below is the emailjs sendgrid service ID, currently the service ID is on emailjs SES
            //o4kPzGSdQOmAr6glaottUQ 
            return(emailjs.send("service_pvqsvuo", "template_31mwxhs", {
                reply_to: placenciaEmail,
                subject: "Successful Tour Booking",
                from_name: "Southern Belize Tours Team",
                email: this.state.emailContent,
                userContent: customerEmail, 
                guideContent: operatorEmail, 
            }).then(function () {
                console.log("Success sending email");
            }, function (error) {
                console.log("Failure to send email", error);
            }));
        }
        else return false; 
    }

    transportationCallback(transportationCase){
        this.setState({hotel: transportationCase, hotelContent: ""}); 
        this.render(); 
    }

    messageForUsCallback(textValue, status) {
        this.setState({ msgContent: textValue.trim(), msgStatus: status });
    }

    firstNameCallback(textValue, status) {
        this.setState({ firstNameContent: textValue.trim(), firstNameStatus: status });
    }

    lastNameCallback(textValue, status) {
        this.setState({ lastNameContent: textValue.trim(), lastNameStatus: status });
    }

    emailCallback(textValue, status) {
        this.setState({ emailContent: textValue.trim(), emailStatus: status });
    }

    tourCallback(tourArray, status, totalPrice, totalPriceChild) {
        //this.setState({ basePrice: totalPrice, totalPrice: totalPrice*parseInt(this.state.selectContent), toursSelected: tourArray, tourStatus: status });
        this.setState({ 
            basePrice: totalPrice, 
            basePriceChild: totalPriceChild, 
            totalPrice: totalPrice*parseInt(this.state.numAdults) + totalPriceChild*(parseInt(this.state.selectContent)-this.state.numAdults), 
            toursSelected: tourArray, tourStatus: status });

    }

    hotelCallback(textValue, status) {
        this.setState({ hotelContent: textValue.trim(), hotelStatus: status }); 
    }

    /*                <script src="https://www.paypal.com/sdk/js?clientid=AZEG07CadJU1ssSNdXVLtOMFWY6UTVjzPQdKQr1aPHDyUYH5ES6EjQZ5x_F5LJ6_Ec4Co1P5uTT60x6b"/> 
    */ 
    render() {
        return (
             <div className={this.props.iframe ? "contactBody opaque" : "contactBody"}>
                {!this.props.iframe ? 
                    <div className="homeTitle">Reserve Your Adventure</div>
                : null}
                <div class={this.isValid() ? "contactForm green" : "contactForm"}>
                    {this.props.iframe ? 
                        <div>
                            <div className="homeTitle centered">Reserve Your Adventure</div>
                            <div className="exitBookingContainer" onClick = {()=>{this.props.closeFunction()}}>
                                <div className = "exitBookingText">Exit Booking Form</div> 
                                <div className = "exitBooking"></div>
                            </div>
                        </div> 
                        : null}
                    <form className="contactFormContent"
                        onSubmit={this.handleSubmit}>
                        <TextField for="firstName"
                            text = {true}
                            criteriaFunction={(x) => { return x.trim().length > 0 }}
                            placeholder="Eiluv"
                            labelText="First Name"
                            changeFunction={this.firstNameCallback} />
                        <TextField for="lastName"
                            text = {true}
                            criteriaFunction={(x) => { return x.trim().length > 0 }}
                            placeholder="Toobing"
                            labelText="Last Name"
                            changeFunction={this.lastNameCallback} />
                        <TextField for="email"
                            text = {true} 
                            criteriaFunction={(x) => { return emailRegex.test(x.trim()); }}
                            placeholder="jdoe@example.com"
                            labelText="Email Address"
                            changeFunction={this.emailCallback} />
                        <div className="contactFormField sizeOfParty">
                            <label for="Size of Party"
                                name="sizeOfParty"
                                id="sizeOfParty"
                                required>
                                Size of Party ages 6+ (Minimum 2 People)
                            </label>
                            <select className="formSelect"
                                onChange={(event) => {
                                    this.setState({ selectContent: event.target.value }); 
                                    this.setState({ numAdults: event.target.value }); 
                                    if (this.state.toursSelected.length > 0) {
                                        this.setState({ totalPrice: this.state.basePrice * event.target.value }); 
                                    }
                                }}>
                                <option selected>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                                <option>10</option>
                            </select>
                        </div>
                        <div className="contactFormField numChildren">
                                <label for="numChildren"
                                    name="numChildren"
                                    id="numChildren"
                                    required>
                                    Number of Children (Ages 5 and Under)
                                </label>
                                <a className="incrBtn"
                                   onClick={(event) => {
                                    let x = this.state.numTinies; 
                                    if(x > 0){
                                        x--; 
                                        this.setState({numTinies: x, tinyMessage: false});
                                    } 
                                 }}>-</a> 
                                <span>{this.state.numTinies}</span>
                                <a className="incrBtn"
                                    onClick={(event) => {
                                        let x = this.state.numTinies; 
                                        if(x < 8){
                                            x++; 
                                            this.setState({numTinies: x, tinyMessage: false}); 
                                        }
                                        else this.setState({tinyMessage: true}) 
                                    }}>+</a> 
                        </div>
                        <div className = {this.state.tinyMessage ? "bookingMessage" : "bookingMessage hidden"}>Please <a className="prettyLink" href = "/contact">contact us</a> for parties with more than 8 children under 5 years old</div>
                        <div className = "contactFormField finalField">
                            <label for="location"
                                   name="location"
                                   id="location"
                                   required>
                                How are You Getting to Placencia (Check/Select One)
                                <span className={this.state.hotelStatus ? "hidden" : "red"}>*</span> 
                                <span className={this.state.hotelStatus ? "greenCheck" : "hidden"}></span>
                            </label>
                            <BookingTransportationGrid callbackFunction = {this.transportationCallback}
                                                       hotelCallback = {this.hotelCallback}/> 
                        </div> 
                        <CheckboxTours tours={this.tours}
                                       selectedTours = {this.props.selectedTours ? this.props.selectedTours : null}
                                       bundles = {this.props.bundles}
                                       cruise={this.state.hotel==2}
                                       cayes={this.state.hotel==3}
                                       changeFunction={this.tourCallback}
                                       bundleCallback = {this.bundleCallback}
                                       purchase={true}/>
                        <TourDate inputChange={this.dateInputChangeCallback} />
                        <TotalCost value={this.state.totalPrice}/> 
                        {this.isValid() ? <PPBtn totalPrice = {this.state.totalPrice} clickFunction = {this.handleSubmitClick}/> 
                                        : <SubmitButton clickFunction={() => { 
                                            let alertMessage = ""; 
                                            if(!this.state.firstNameStatus) alertMessage += "Please specify a first name\n";
                                            if(!this.state.lastNameStatus) alertMessage += "Please specify a last name\n";
                                            if(!this.state.emailStatus) alertMessage += "Please specify a valid email address\n";
                                            if(!this.state.tourStatus) alertMessage += "Please select at least one tour to book\n";
                                            if(!this.state.dateStatus) alertMessage += "Please specify a valid date for your tour\n";
                                            if(!this.state.hotelStatus) alertMessage += "Please specify a place of embarkment\n";
                                            alert(alertMessage); 
                                        }} content="Fill All Input to Continue" />}
                    </form>
                </div> 
             </div> 
        );
    }
}

export default BookNow;

/* In order to re-implement child-pricing, uncomment the below code block and 
 * cut & paste it below the size of party contact form field. 
 *
 * NOTE: with this, nothing needs to be done with the BookNow state, which 
 *       still computes child pricing, it simply does not allow numChildren to
 *       be greater than 0 without the incr buttons in the below code block. 
/*
<div className="contactFormField finalField dualForm">
                            <div> 
                                <label for="numAdults"
                                    name="numAdults"
                                    id="numAdults"
                                    required>
                                    Number of Adults
                                </label>
                                <a className="incrBtn"
                                   onClick={(event) => {
                                       let x = this.state.numAdults; 
                                       if(x > 1){
                                           x--; 
                                           this.setState({numAdults: x}); 
                                           this.setState({ totalPrice: this.state.basePrice * x + this.state.basePriceChild * (this.state.selectContent - x)});
                                       } 
                                   }}>-</a> 
                                <span>{this.state.numAdults}</span>
                                <a className="incrBtn"
                                   onClick={(event) => {
                                       let x = this.state.numAdults; 
                                       if(x < this.state.selectContent){
                                           x++; 
                                           this.setState({numAdults: x});
                                           this.setState({ totalPrice: this.state.basePrice * x + this.state.basePriceChild * (this.state.selectContent - x)});

                                       } 
                                    }}>+</a> 
                            </div> 
                            <div> 
                                <label for="numChildren"
                                    name="numChildren"
                                    id="numChildren"
                                    required>
                                    Number of Children (Ages 5-11)
                                </label>
                                <a className="incrBtn"
                                   onClick={(event) => {
                                    let x = this.state.numAdults; 
                                    if(x < this.state.selectContent){
                                        x++; 
                                        this.setState({numAdults: x});
                                        this.setState({ totalPrice: this.state.basePrice * x + this.state.basePriceChild * (this.state.selectContent - x)});

                                    } 
                                 }}>-</a> 
                                <span>{this.state.selectContent - this.state.numAdults}</span>
                                <a className="incrBtn"

                                    onClick={(event) => {
                                        let x = this.state.numAdults; 
                                        if(x > 1){
                                            x--; 
                                            this.setState({numAdults: x}); 
                                            this.setState({ totalPrice: this.state.basePrice * x + this.state.basePriceChild * (this.state.selectContent - x)});
                                        } 
                                    }}>+</a> 
                            </div> 
                        </div>
*/ 