import React, { Component } from 'react';   
import FilterButton from './FilterButton.js'; 
import TourBundleGrid from './TourBundleGrid'; 
import TourParagraph from './TourParagraph';

class BundleTours extends React.Component{
    constructor(props){
        super(); 
        this.options = ["Hotel Visitors", "Caye Visitors", "Cruise Passengers"]; 
        this.filterCallback = this.filterCallback.bind(this); 
        this.state = {
            filter: null
        }; 
    }

    filterCallback(e){
        this.setState({
            filter: e.target.innerHTML
        });
    }

    render(){
        return(
            <div>
                <TourParagraph text = {[]}
                               title = {this.props.bundleTitle}/> 
                <FilterButton options = {this.options} onChangeFunction = {this.filterCallback}/> 
                <TourBundleGrid tours = {this.props.tours} 
                                clickBundleFunction = {this.props.clickBundleFunction}
                                bundles = {this.props.bundles}
                                filter = {this.state.filter}/> 
            </div>
        );
    }
}
export default BundleTours; 