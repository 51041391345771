import PropTypes from 'prop-types';

function DesktopNavbarBottom({ siteUrls, tours }) {
    return (
        <div className="desktopNavbarBottom">
            <div className="desktopMenuContainer">
                {siteUrls.map(page => (

                    page.id === "desktopLand" ?
                        <a href={page.url}>
                            <div className={"desktopMenuOption " + (page.id ? "desktopDropdown" : "")}>
                                <div>
                                    <a href={page.url}> {page.text} </a>
                                    <div id={page.id}>
                                        {tours.map(tour => (
                                            <a href={tour.url}>
                                                <div className="desktopNavbarTourStripe"></div>
                                                {tour.text}
                                            </a>))}
                                    </div>
                                </div>
                            </div>
                        </a>
                        :
                        page.id === "desktopCruise" ?
                            <a href={page.url}>
                                <div className={"desktopMenuOption " + (page.id ? "desktopDropdown" : "")}>
                                    <div> 
                                        <a href={page.url}> {page.text}</a> 
                                    <div id={page.id}> 
                                    {
                                        tours.filter(tour => tour.cruise === true).map(filteredTour => (
                                            <a href={filteredTour.url}>
                                                <div className="desktopNavbarTourStripe"></div> 
                                                {filteredTour.text}
                                            </a> 
                                        ))          
                                    }
                                        </div> 
                                        </div> 
                                </div> 
                            </a>
                        : page.url && page.text ? 
                        <a className="desktopMenuLink" href={page.url}>
                                {page.text}
                                <div className="desktopNavbarStripe"></div>
                        </a>
                        :
                        <></>
                ))}
            </div> 
        </div>
    );
}

export default DesktopNavbarBottom;