
function GridOptionsShowToggle({toggled, clickEvent}) {
    return (
        <div className = "showContainer" onClick = {()=>{clickEvent();}}>
            <span className="showMoreText">
                {toggled  ? "Show Less" : "Show More"}
            </span>
            <div className="showArrowContainer">
               <div className={toggled ? "showArrow toggled" :"showArrow"}></div> 
        </div>
        </div>
    );
}

export default GridOptionsShowToggle; 