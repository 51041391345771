//Styles
import './../App.css';
import '../Styles/ThankYou.css';

import React from 'react';

class ThankYou extends React.Component {

    constructor(props) {
        super(); 
    }

    render() {
        return (
            <div className="thankYouContainer">
                <div className="containerText">
                    <div className="homeTitle">
                        Your Adventure is Booked!
                    </div> 
                    <div className="textRow">
                        We are very excited to show you the magical wonders that Belize has to offer!
                    </div> 
                    <div className="textRow">
                        You should receive a confirmation email at the address specified.
                    </div> 
                    <div className="textRow">
                        We will have one of our professional tour guides contact you shortly regarding your tour booking. 
                    </div> 
                    <div className="textRow">
                        Check out our FAQ and prepare so that you can make the most of your time in Placencia. 
                    </div> 
                    <div className="textRow">
                        Remember if you are a Norweigan Cruise Lines guest docking at Harvest Caye, be sure to book 
                        your tickets for the Harvest Caye Ferry to Placencia Docks where we will have one of our guides 
                        meet you. 
                    </div> 
                    <div className="textRow">
                        If you have any questions about your tour reservation, or need to change dates, 
                        please don't hesitate to contact us and we will get back to you as soon as we can!
                    </div> 
                </div> 
            </div>
        );
    }
}

export default ThankYou; 