import React, { Component } from 'react'; 
import logo from './../logo.svg';
import './../App.css';
import DesktopNavbar from './DesktopNavbar.js';
import App from '../App.js';
import AllTours from './AllTours.js'; 
import CruiseTours from './CruiseTours.js'; 
import Title from './Title.js'; 
import FilterButton from './FilterButton.js'; 

import AboutGrid from './AboutGrid.js'; 
import '../Styles/About.css'; 

const titleText = "Cave Tubing Frequenty Asked Questions";

const safety = <div>Please see our full safety guidelines <a className = "prettyLink"href="/safety">here</a>.</div>;
const questions = [
    {
        question: "Will we have time to go cave tubing? Will you get us back to the ship on time?", 
        answer: "Absolutely! The entire cave tubing tour from the dock where you disembark from your tender, to the Caves Branch Archeological site, the cave tubing tour, and back to the dock is about 4 hours 30 minutes; with lunch, about 5 hours, and if you buy the COMBO TOUR : cave tubing and zipline, about 6 hours total. We stay UP TO DATE on all of the visiting cruise ship schedules to doubly ensure our guests arrive back at the dock in good time, comfortably, and with some shopping time. ",
        categories: ["logistics", "cruise"]
    },
    {
        question: "Which tender should I take from the cruise ship?", 
        answer: "For our cruise passenger guests: please catch the earlier tender(s) from the ship to shore. We meet you at the dock on shore inside Belize City’s Tourism Village – look for the signboard which reads: Major Tom & Minor Tom, Cave Tubing, Greenwood Adventures",
        categories: ["logistics", "cruise"]
    },
    {
        question: "My cruise ship has had a change in itinerary or web confirmation. Will I still be able to go on the tour?", 
        answer: "We are always made well aware of the sometimes sudden itinerary changes on arrival dates for any ship. If this happens with you on board----not to worry – we will be on shore at the dock on the new arrival day/date. At rare times the web may fail to deliver your confirmation from us – if that happens, (1) Please contact Major Tom at info@cave-tubing.net AND (2) we are always at the Belize Port Dock every cruise ship visiting day – we will see you as you come ashore off your tender, and you will see us. Major Tom, Tom Jr. and their veteran guides – an adventure, not just a tour!",
        categories: ["logistics", "cruise"]
    },
    {
        question: "We are staying on the Cayes overnight. How do we meet you for our tour?", 
        answer: "For our overnight guests who may be staying on the Cayes: we will be there to meet you at the dock in Belize City. The Caye Caulker water taxi association has boats every hour starting at 8.30 am from Caye Caulker and San Pedro to Belize City and returning again every hour on the hour to the Cayes. Guests can view the taxi schedule at cayecaulkerwatertaxi.com and get all the details. No one is turned back, as several boats are always available and on call.",
        categories: ["logistics", "cayes"]
    },
    {
        question: "Can we include lunch with the limited timing?", 
        answer: "Yes, we have many restaurants that feature fine Belizean cuisine, which are sure to please.",
        categories: ["food"]
    },
    {
        question: "Where do we meet? How quickly are we on our way?", 
        answer: "You will be met at the dock in Belize City by one of our friendly guides as you step off your tender boat. No need to go hunting for terminals or gates - your names will clearly be displayed on our signboard which reads: Major Tom & Minor Tom. We depart to the Caves Branch Archeological site as soon as we have a handle on the guests.",
        categories: ["logistics", "cruise"]
    },
    {
        question: "Where do we meet? How quickly are we on our way?", 
        answer: "You will be met at the dock in Belize City by one of our friendly guides as you step off your tender boat. No need to go hunting for terminals or gates - your names will clearly be displayed on our signboard which reads: Major Tom & Minor Tom. We depart to the Caves Branch Archeological site as soon as we have a handle on the guests.",
        categories: ["logistics", "cruise"]
    },
    {
        question: "Is the cave tubing tour safe/appropriate for young children?", 
        answer: " Yes! We have tubes, life-vests and lights that fit the young ones properly and we work to make sure that both they and the adults feel safe and have fun. We have discovered over the years, that this is a tour the children absolutely love to take. They handle the leisurely rainforest hike extremely well too.",
        categories: ["logistics", "children"]
    },
    {
        question: "What should I wear?", 
        answer: "Feel free to wear bathing suits, trunks, shorts/shirt or blouse, in fact wear what you feel comfortable with. Footwear should be secure. Flip Flops are NOT recommended. Water shoes are rented at the cave park for US $3.",
        categories: ["logistics", "things to bring"]
    },
    {
        question: "What else can I bring?", 
        answer: " Waterproof camera, towel, extra clothes to change into, hat or cap if you would like to, and of course, you may bring snacks with you if you prefer that.",
        categories: ["logistics", "things to bring"]
    },
    {
        question: "Do you accomodate persons with limited mobility?", 
        answer: "Yes, we do! Simply let us know and we will make extra staff available to ensure that you enjoy your tour.",
        categories: ["logistics", "accomodations"]
    },
    {
        question: "What about refunds if the ship changes course, itinerary, or simply does not sail? What about refunds if weather cancels the trip? ", 
        answer: "We offer our guests three flexible payment options: prepay, prepay 10% or pay while in Belize. Should your ship or Mother Nature postpone your trip, we fully refund the cost of your trip through the U.S based PayPal system within the typical two month period, no problem. (After 60 days of your reservation date we still fully refund you minus PayPal's .03%) We hope you will be able to enjoy the tour soon.",
        categories: ["payment"]
    },
    {
        question: "When I buy with US dollars, do I get US dollars in return?", 
        answer: "Yes, absolutely. Ask for US dollars in change wherever you buy – Belizeans have come to recognize that need. Remember, ONE US dollar equals TWO Belizean dollars.",
        categories: ["payment"]
    },
    {
        question: "Are there shops at the Caves Branch Archeological site?", 
        answer: "Yes, there are, for Belizean handicraft, waterproof cameras, towels, shirts and shorts. If there is something special you might to purchase on the way to the caves or back, please ask your guide – he will gladly accommodate.",
        categories: ["souveniers"]
    },
    {
        question: "What restrictions are there on photography at the caves?", 
        answer: "None at all, except if you are a professional photographer out to take “pro” photos – you need special permission from the authorities, otherwise, camera buffs – shoot away!",
        categories: ["souveniers"]
    },
    {
        question: "What are your safety guidelines?",
        answer: safety,
        categories: ["logistics"]
    }
];

class FAQ extends React.Component{
    
    constructor(props){
        super(); 

        this.state = {
            categories: ["All"], 
            filter: "" 
        }; 

        this.filterCallback = this.filterCallback.bind(this); 
    }


    //Take in all question categories
    mapCategories(){
        let tempCategories = [...this.state.categories]; 
        questions.map(questionList => {questionList.categories.map(category => 
            {
                if(!tempCategories.includes(category))tempCategories.push(category)
            })
        });
        this.setState({categories: tempCategories}); 
    }

    componentDidMount(){
        this.mapCategories(); 
    }



    filterCallback(e){
        this.setState({filter: e.target.innerHTML}); 
    }


render() {
    return (
        <div className="tourBody">
                <div className = "faqTitle">
                    <Title text={titleText} /> 
                </div>
                <div className = "faqFilter">
                    <FilterButton options = {this.state.categories} onChangeFunction = {this.filterCallback}/> 
                </div>
                {questions.map(question => (
                        this.state.filter === "" || this.state.filter === "All" || question.categories.includes(this.state.filter) ? 
                        <div className="questionBox">
                            <div className="question"> {question.question}</div> 
                            <div className="answer"> {question.answer}</div> 
                        </div> 
                        : null 
                    
                ))}
        </div>
    );
}

}
export default FAQ;
/*
  <script async src="https://www.jscache.com/wejs?wtype=certificateOfExcellence&amp;uniq=255&amp;locationId=1027073&amp;lang=en_US&amp;year=2019&amp;display_version=2" data-loadtrk onload="this.loadtrk=true"></script> 
  */ 