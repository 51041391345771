import React from 'react';

function TripAdvisorWidget() {
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.async = true;
  //   script.src = 'https://www.jscache.com/wejs?wtype=certificateOfExcellence&amp;uniq=594&amp;locationId=1027073&amp;lang=en_US&amp;year=2019&amp;display_version=2';
  //   script.setAttribute('data-loadtrk', 'true');
  //   document.getElementById('tripadvisor-widget-container').appendChild(script);

  //   return () => {
  //     document.getElementById('tripadvisor-widget-container').removeChild(script);
  //   }
  // });
  return (
    <>
        <div id="TA_rated295" className="TA_rated">
            <ul id="B8pBTLXS0dK" className="TA_links 6AtxNX">
                <li id="MPEWsC1" className="qklV4ADxwz">
                    <a target="_blank" href="https://www.tripadvisor.com/Attraction_Review-g291977-d20387654-Reviews-Placencia_Action_Tours-Placencia_Stann_Creek.html">
                        <img src="https://www.tripadvisor.com/img/cdsi/img2/badges/ollie-11424-2.gif" alt="TripAdvisor"/>
                    </a>
                </li>
            </ul>
        </div>
    </>
  );
}

export default TripAdvisorWidget;

// Cave-tubing Stuff
{/* <div id="TA_certificateOfExcellence594" className="TA_certificateOfExcellence">
<ul id="jcpQz5nLl" className="TA_links 8mpc3LTfkd">
  <li id="Ppel9X" className="IrnynOB">
    <a target="_blank" href="https://www.tripadvisor.com/Attraction_Review-g291968-d1027073-Reviews-Cavetubing_with_Major_Tom-Belize_City_Belize_District.html">
      <img src="https://www.tripadvisor.com/img/cdsi/img2/awards/v2/coe-14348-2.png" alt="TripAdvisor" className="widCOEImg" id="CDSWIDCOELOGO"/>
    </a>
  </li>
</ul>
</div> */}
{/* 
<div id="TA_certificateOfExcellence594" class="TA_certificateOfExcellence">
    <ul id="jcpQz5nLl" class="TA_links 8mpc3LTfkd">
        <li id="Ppel9X" class="IrnynOB">
        <a target="_blank" href="https://www.tripadvisor.com/Attraction_Review-g291968-d1027073-Reviews-Cavetubing_with_Major_Tom-Belize_City_Belize_District.html">
            <img src="https://www.tripadvisor.com/img/cdsi/img2/awards/v2/coe-14348-2.png" alt="TripAdvisor" class="widCOEImg" id="CDSWIDCOELOGO"/>
        </a>
        </li>
        </ul>
        </div>
        <script async src="https://www.jscache.com/wejs?wtype=certificateOfExcellence&amp;uniq=594&amp;locationId=1027073&amp;lang=en_US&amp;year=2019&amp;display_version=2" data-loadtrk onload="this.loadtrk=true">

</script> */}