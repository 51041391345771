import DesktopNavbarText from './DesktopNavbarText.js';
import DesktopLogo from './DesktopLogo.js'; 
import DesktopNavbarBottom from './DesktopNavbarBottom.js';
import imgSrcVar from './../images/companyLogoDesktop.webp';


function DesktopNavbar({ siteUrls, tours }) {
    return (
        <div className="desktopNavbar">
            <div className="desktopNavbarLogoSpace" href="/">
                <div className = "desktopLogoBorder">
                    <DesktopLogo imgSrc={imgSrcVar}/>
                </div>
            </div>
            <DesktopNavbarText text="Placencia Action Tours"/>
            <DesktopNavbarBottom siteUrls={siteUrls} tours = { tours }/>
        </div>
    );
}

export default DesktopNavbar;