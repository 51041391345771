import BottomBarLogoMenu from './BottomBarLogoMenu.js';
import footerImg from '../images/companyLogoBottomBar.webp';

function Footer({ followUs, bottomBarGridItems }) {
    return (
        <nav className="bottomBar">
            <div>
                <div className="bottomBarImgContainer">
                    <a href = "/">
                        <img src={footerImg}
                             height="234px"
                             width="216px"
                             alt="Placencia Action Tours company Logo: The Belizean Quetzocoatl" /> 
                    </a> 
                </div> 
                <div className="bottomBarLogoText">
                    <div class="bottomBarLogoTextBack">
                        Placencia
                    </div> 
                    <div>
                        Action
                    </div>
                    <div class="bottomBarLogoTextBack">
                        Tours
                    </div> 
                </div> 
            </div>
            <BottomBarLogoMenu followUs={followUs} bottomBarGridItems={bottomBarGridItems}/> 
        </nav>
    );
}

export default Footer;