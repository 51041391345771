import React from 'react';

function TourCard({ tour, bundle, purchase, bookTourFunction }) {
    return (
        <div>
            {bundle ? 
            <div className="imageCard small lazy" style={{backgroundImage: `url(${tour.cardImage})`}}>
                <div className="imageCardContent smallCard">
                    <h2 className="imageCardCaption">
                        {tour.text}
                    </h2>
                </div> 
            </div> 
                 : 
            purchase ?
            <div className="imageCard purchaseCard lazy" style={{backgroundImage: `url(${tour.cardImage})`}}>
            <div className="imageCardContent">
                <h2 className="imageCardCaption">
                    {tour.text}
                </h2>
                <p className="imageCardBody">
                    {tour.ShortDesc}
                </p>
                <p className="imageCardBody imageBodyGrid">
                    <div>
                       Per Person
                    </div>
                    <div>
                    </div>
                    <div className="price">
                        ${tour.price}
                    </div>
                    <div className="price">
                    </div>
                </p>
            </div>
        </div>
            :
            <div className="imageCard lazy" style={{backgroundImage: `url(${tour.cardImage})`}}>
                <div className="imageCardContent">
                    <h2 className="imageCardCaption">
                        {tour.text}
                    </h2>
                    <p className="imageCardBody">
                        {tour.ShortDesc}
                    </p>
                    <p className="imageCardBody imageBodyGrid">
                        <div>
                            Per Person
                        </div> 
                        <div> 
                        </div> 
                        <div className="price">
                            ${tour.price}
                        </div> 
                        <div className="price"> 
                        </div> 
                    </p>
                    <div className="imageCardBtns">
                        <a href={tour.url} className="imageCardBtn">
                            View More
                        </a> 
                        <div onClick = {(event)=>{
                                bookTourFunction([tour.name]); 
                            }} 
                           className="imageCardBtn">
                            Book Now
                        </div>
                    </div> 
                </div> 
            </div> 
            }
        </div>
    );
}

export default TourCard;