import PropTypes from 'prop-types';

function DesktopLogo({ imgSrc }) {
    return (
        <a href="/">
            <img className="desktopLogo"
                 src={imgSrc}
                 alt="Placencia Action Tours company Logo: The Belizean Quetzocoatl">
            </img>
        </a> 
    );
}

DesktopLogo.propTypes = {
    imgSrc: PropTypes.string.isRequired
}

export default DesktopLogo;