import React, { Component } from 'react';   //Use of class-based react components
import * as emailjs from 'emailjs-com';     //Use of emailjs to avoid smpt server 
import ReactDOMServer from 'react-dom/server'; 
import { withRouter } from 'react-router-dom';
import {broswerHistory} from 'react-router-dom'; 
import CheckboxTours from './CheckboxTours.js'; 
import MessageForUs from './MessageForUs.js'; 
import SubmitButton from './SubmitButton.js'; 
import TourDate from './TourDate.js'; 
import TextField from './TextField.js'; 
import palmImage from './../images/palm.jpg';
import BookingTransportationGrid from './BookingTransportationGrid.js'; 



const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const adminEmail = "info@southernbelizetours.com";  

class ContactFormContent extends React.Component {

    constructor(props) {
        emailjs.init("user_HL1bjtoOHSEOpRUaSXa55");
        super();
        this.contactFields = props.contactFields; 
        this.tours = props.tours; 

        /*Status requirements:
         * message field needs to be at least 17 chars long
         * first name has to be at least 1 character long 
         * last name has to be at least 1 character long
         * email has to pass regex test 
         * They have to select at least one tour 
         */ 
        this.state = {
            msgContent: "", msgStatus: false,
            firstNameContent: "", firstNameStatus: false,
            lastNameContent: "", lastNameStatus: false,
            emailContent: "", emailStatus: false, 
            selectContent: "2", 
            toursSelected: [], tourStatus: false,
            dateContent: "", dateStatus: false,
            hotel: true,
            hotelContent: "", hotelStatus: false 
        };
        this.messageForUsCallback = this.messageForUsCallback.bind(this); 
        this.firstNameCallback = this.firstNameCallback.bind(this); 
        this.lastNameCallback = this.lastNameCallback.bind(this); 
        this.emailCallback = this.emailCallback.bind(this); 
        this.tourCallback = this.tourCallback.bind(this); 
        this.dateInputChangeCallback = this.dateInputChangeCallback.bind(this); 
        this.handleSubmitClick = this.handleSubmitClick.bind(this); 
        //this.hotelCallback = this.hotelCallback.bind(this); 
        this.hotelCallback = this.hotelCallback.bind(this); 
        this.transportationCallback = this.transportationCallback.bind(this); 
        this.addOtherTour(); 
    }

    transportationCallback(transportationCase){
        this.setState({hotel: transportationCase, hotelContent: ""}); 
        this.render(); 
    }

    transportationCallback(transportationCase){
        this.setState({hotel: transportationCase, hotelContent: ""}); 
        this.render(); 
    }

    addOtherTour(){
        let x ={    url: '/', 
                    cayes: true,
                    priceChild: 100,
                    price: ' ?', 
                    name: "Other", 
                    cardImage: palmImage, text: "Other Tour", cruise: true, ShortDesc: "Please specify your adventure in question in your message below."
        };
        let temp = [...this.tours];
        temp.push(x); 
        this.tours = [...temp];
    }

    /*Returns the status of the state variables for all of the form contents*/
    isValid() {
        return this.state.firstNameStatus
            && this.state.lastNameStatus
            && this.state.emailStatus
            && this.state.tourStatus
            && this.state.dateStatus
            && this.state.msgStatus
            && (this.state.hotel ? this.state.hotelStatus : true);
    }


    dateInputChangeCallback(textValue, status) {
        this.setState({ dateContent: textValue, dateStatus: status });
    }

    //Helper function that makes list of tours into a string
    makeTourString(arr) {
        let ret = ""; 
        for (let i = 0; i < arr.length; ++i) {
            ret += arr[i] + "\n"; 
        }
        return ret; 
    }

    produceOperatorHtmlEmail(){
        let fullTemplate =
            <div style={{fontWeight:'400', fontSize: '1.2rem', color: 'black'}}>
                <div style = {{width: '100%', height: '5rem', backgroundColor:"#4da5fd"}}>
                    <div style = {{width: "85px", height: "85px", background: "linear-gradient(150deg,rgb(77,165,273),#000000 117%)", borderRadius: "45px", display: "flex", marginLeft: "auto", marginRight: "auto", borderStyle: "solid", borderColor: "black", borderBottomColor: "white"}}>
                        <img style = {{display: 'flex', paddingLeft: 'auto', paddingRight: 'auto', position: "absolute", top: "5px", left: "5px"}} src = "https://www.southernbelizetours.com/static/media/companyLogoDesktop.0324ccd1.png"/> 
                    </div> 
                </div> 
                <p>Julian and PAT Staff,</p>
                <p style = {{marginBottom: '2rem'}}>This is an automated email notifying of a customer question. Details on their question are below, please respond to their email address provided so that we can secure this booking!</p> 
                <p style={{color: 'black', padding: "0px 2rem"}}>Hello {this.state.firstNameContent + " " + this.state.lastNameContent},</p>
                <p style={{color: 'black', padding: "0px 2rem"}}>This is an automated message confirming that our guides have received your question! <span style={{fontWeight: '600'}}>Expect one of Placencia Action Tours's (Southern Belize Tours) professional guides to contact you shortly to provide clarification.</span> Please verify your contact information in the below details is accurate so that our guides can reach out to you. Please email info@southernbelizetours.com to correct any of the details in your question below.</p> 
                <div style={{
                             borderStyle: 'solid',
                             borderWidth: '2px', 
                             borderRadius: '5px',
                             borderColor: '#c83d3a',
                             padding: '10px',}}>
                        <div style={{width: '100%',
                             textAlign: 'center',
                             verticalAlign: 'middle',
                             color:'#f7c400f7', 
                             fontSize: '1.8rem',
                             marginBottom: '4%',
                             fontWeight: '600' }}>
                            User Details 
                        </div>
                        <div style={{width: '100%',
                                     marginBottom: '1%'}}> 
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Full Name:</div>
                                <div style={{color: '#4da5fd'}}>{this.state.firstNameContent + " " + this.state.lastNameContent}</div>
                            </div> 
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Tour Date in Question:</div>
                                <div style={{color: '#4da5fd'}}>{this.state.dateContent}</div>
                            </div>
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Potential Party Size:</div>
                                <div style={{color: '#4da5fd', fontWeight: '400', fontSize: '1.2rem'}}>{this.state.selectContent} Adults</div>
                            </div>
                        </div> 
                        <div style={{width: '100%',
                                     marginBottom: '1%'}}> 
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Email:</div>
                                <div style={{color: '#4da5fd'}}>{this.state.emailContent}</div>
                            </div>
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>
                                    Intended Method of Arriving in Placencia: 
                                </div> 
                                <div style={{color: '#4da5fd', fontWeight: '400', fontSize: '1.2rem'}}>
                                        {this.state.hotel==1 ? <span>
                                                                  A Hotel in Placencia Area
                                                              </span>
                                        :this.state.hotel==2 ? <span>
                                                                  Norweigan Cruiselines tendering to Harvest Caye, taking the ferry to Placencia Docks
                                                                  <span style={{fontSize: '.7rem', verticalAlign: 'top', color: '#c83d3a'}}>1</span>
                                                                </span>
                                        :null}
                                </div>
                            </div>
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>{this.state.hotel==1? "Hotel Name" : this.state.hotel==2? "Cruise Name" : "Caye Name"}:</div>
                                <div style={{color: '#4da5fd'}}>{this.state.hotelContent}</div>
                            </div>
                        </div>
                    <div  style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Tours in Question: </div>
                    <ol>
                    {
                        this.state.toursSelected.map(selectedTour=>
                            (selectedTour.text==="Cave Tubing" ? <li style={{color: 'black', marginBottom: '8px'}}>{selectedTour.text} <span style={{fontWeight: '600'}}><a style={{fontWeight: "500", margin: "0 1%", textDecoration: "none", cursor: "pointer", color: 'black', borderStyle: 'solid', borderWidth: '2px', borderColor: '#4da5fd', color: '#4da5fd', borderRadius: '5px', padding: '2px 5px'}}href={"https://southernbelizetours.com" + selectedTour.url}>View Tour Details</a>(NOTE: Please review our <a href='southernbelizetours.com/safety'>safety</a> guidelines to prepare for cave tubing)</span></li> : 
                             selectedTour.text==="Rainforest Ziplining" ? <li style={{color: 'black', marginBottom: '8px'}}>{selectedTour.text} <span style={{fontWeight: '600'}}><a style={{fontWeight: "500", margin: "0 1%", textDecoration: "none", cursor: "pointer", color: 'black', borderStyle: 'solid', borderWidth: '2px', borderColor: '#4da5fd', color: '#4da5fd', borderRadius: '5px', padding: '2px 5px'}}href={"https://southernbelizetours.com" + selectedTour.url}>View Tour Details</a>(NOTE: Maximum ziplining weight is 300lbs{this.state.numTinies > 0 ? ". You have also specified bringing children 5 and under. The minimum age for ziplining is 4 years old": null})</span></li>
                                                                :<li style={{color: 'black', marginBottom: '8px'}}>{selectedTour.text} <a style={{fontWeight: '500', margin: "0 1%", textDecoration: "none", cursor: "pointer", color: 'black', borderStyle: 'solid', borderWidth: '2px', borderColor: '#4da5fd', color: '#4da5fd', borderRadius: '5px', padding: '2px 5px'}}href={"https://southernbelizetours.com" + selectedTour.url}>View Tour Details</a></li>))
                    }
                    </ol>
                </div>
                <p>Your Message: </p>
                <p style={{padding:'1rem', color: '#4da5fd'}}>{this.state.msgContent}</p>
                <p style = {{color: 'black'}}>Also, do not forget to look at our <a href="https://southernbelizetours.com/FAQ">Frequenty Asked Questions</a> page and you may find what you have been looking for!</p> 
            </div>; 
        return ReactDOMServer.renderToStaticMarkup(fullTemplate); 
    }

    produceCustomerHtmlEmail(){
        let fullTemplate =
            <div style={{fontWeight:'400', fontSize: '1.2rem', color: 'black'}}>
                <div style = {{width: '100%', height: '5rem', backgroundColor:"#4da5fd"}}>
                    <div style = {{width: "85px", height: "85px", background: "linear-gradient(150deg,rgb(77,165,273),#000000 117%)", borderRadius: "45px", display: "flex", marginLeft: "auto", marginRight: "auto", borderStyle: "solid", borderColor: "black", borderBottomColor: "white"}}>
                        <img style = {{display: 'flex', paddingLeft: 'auto', paddingRight: 'auto', position: "absolute", top: "5px", left: "5px"}} src = "https://www.southernbelizetours.com/static/media/companyLogoDesktop.0324ccd1.png"/> 
                    </div> 
                </div> 
                <p style={{color: 'black', padding: "0px 2rem"}}>Hello {this.state.firstNameContent + " " + this.state.lastNameContent},</p>
                <p style={{color: 'black', padding: "0px 2rem"}}>This is an automated message confirming that our guides have received your question! <span style={{fontWeight: '600'}}>Expect one of Placencia Action Tours's (Southern Belize Tours) professional guides to contact you shortly to provide clarification.</span> Please verify your contact information in the below details is accurate so that our guides can reach out to you. Please email info@southernbelizetours.com to correct any of the details in your question below.</p> 
                <div style={{
                             borderStyle: 'solid',
                             borderWidth: '2px', 
                             borderRadius: '5px',
                             borderColor: '#c83d3a',
                             padding: '10px',}}>
                        <div style={{width: '100%',
                             textAlign: 'center',
                             verticalAlign: 'middle',
                             color:'#f7c400f7', 
                             fontSize: '1.8rem',
                             marginBottom: '4%',
                             fontWeight: '600' }}>
                            User Details 
                        </div>
                        <div style={{width: '100%',
                                     marginBottom: '1%'}}> 
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Full Name:</div>
                                <div style={{color: '#4da5fd'}}>{this.state.firstNameContent + " " + this.state.lastNameContent}</div>
                            </div> 
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Tour Date in Question:</div>
                                <div style={{color: '#4da5fd'}}>{this.state.dateContent}</div>
                            </div>
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Potential Party Size:</div>
                                <div style={{color: '#4da5fd', fontWeight: '400', fontSize: '1.2rem'}}>{this.state.selectContent} Adults</div>
                            </div>
                        </div> 
                        <div style={{width: '100%',
                                     marginBottom: '1%'}}> 
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Email:</div>
                                <div style={{color: '#4da5fd'}}>{this.state.emailContent}</div>
                            </div>
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>
                                    Method of Arriving in Placencia: 
                                </div> 
                                <div style={{color: '#4da5fd', fontWeight: '400', fontSize: '1.2rem'}}>
                                        {this.state.hotel==1 ? <span>
                                                                  A Hotel in Placencia Area
                                                              </span>
                                        :this.state.hotel==2 ? <span>
                                                                  Norweigan Cruiselines tendering to Harvest Caye, taking the ferry to Placencia Docks
                                                                  <span style={{fontSize: '.7rem', verticalAlign: 'top', color: '#c83d3a'}}>1</span>
                                                                </span>
                                        :null}
                                </div>
                            </div>
                            <div style={{width:'30%', verticalAlign: 'top',
                                        marginRight: '2%',
                                        display: 'inline-block'}}>
                                <div style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>{this.state.hotel==1? "Hotel Name" : this.state.hotel==2? "Cruise Name" : "Caye Name"}:</div>
                                <div style={{color: '#4da5fd'}}>{this.state.hotelContent}</div>
                            </div>
                        </div>
                    <div  style={{fontWeight: '600', fontSize: '1.3rem', color: 'black'}}>Tours in Question: </div>
                    <ol>
                    {
                        this.state.toursSelected.map(selectedTour=>
                            (selectedTour.text==="Cave Tubing" ? <li style={{color: 'black', marginBottom: '8px'}}>{selectedTour.text} <span style={{fontWeight: '600'}}><a style={{fontWeight: "500", margin: "0 1%", textDecoration: "none", cursor: "pointer", color: 'black', borderStyle: 'solid', borderWidth: '2px', borderColor: '#4da5fd', color: '#4da5fd', borderRadius: '5px', padding: '2px 5px'}}href={"https://southernbelizetours.com" + selectedTour.url}>View Tour Details</a>(NOTE: Please review our <a href='southernbelizetours.com/safety'>safety</a> guidelines to prepare for cave tubing)</span></li> : 
                             selectedTour.text==="Rainforest Ziplining" ? <li style={{color: 'black', marginBottom: '8px'}}>{selectedTour.text} <span style={{fontWeight: '600'}}><a style={{fontWeight: "500", margin: "0 1%", textDecoration: "none", cursor: "pointer", color: 'black', borderStyle: 'solid', borderWidth: '2px', borderColor: '#4da5fd', color: '#4da5fd', borderRadius: '5px', padding: '2px 5px'}}href={"https://southernbelizetours.com" + selectedTour.url}>View Tour Details</a>(NOTE: Maximum ziplining weight is 300lbs{this.state.numTinies > 0 ? ". You have also specified bringing children 5 and under. The minimum age for ziplining is 4 years old": null})</span></li>
                                                                :<li style={{color: 'black', marginBottom: '8px'}}>{selectedTour.text} <a style={{fontWeight: '500', margin: "0 1%", textDecoration: "none", cursor: "pointer", color: 'black', borderStyle: 'solid', borderWidth: '2px', borderColor: '#4da5fd', color: '#4da5fd', borderRadius: '5px', padding: '2px 5px'}}href={"https://southernbelizetours.com" + selectedTour.url}>View Tour Details</a></li>))
                    }
                    </ol>
                </div>
                <p>Your Message: </p>
                <p style={{padding:'1rem', color: '#4da5fd'}}>{this.state.msgContent}</p>
                <p style = {{color: 'black'}}>Also, do not forget to look at our <a href="https://southernbelizetours.com/FAQ">Frequenty Asked Questions</a> page and you may find what you have been looking for!</p> 
            </div>; 
        return ReactDOMServer.renderToStaticMarkup(fullTemplate); 
    }

    /*Checks to make sure the form is happy, sends the email, and looks into the status of the email*/ 
    handleSubmitClick() {
        if (this.isValid()) {
           //generates html static markups for email content for both operators and customers
           let customerEmail = this.produceCustomerHtmlEmail(); 
           let operatorEmail = this.produceOperatorHtmlEmail(); 
           return(emailjs.send("service_pvqsvuo", "template_31mwxhs", {
                reply_to: adminEmail,
                subject: "southernbelizetours.com: Question Regarding a Tour",
                from_name: "Southern Belize Tours Team",
                email: this.state.emailContent,
                userContent: customerEmail,
                guideContent: operatorEmail
            }).then(
            function () {
                console.log("Success sending email");
            }, function (error) {
                    console.log("Failure to send email");
                    console.log(error); 
            })); 
        }
        else{
            let alertMessage = ""; 
            if(!this.state.firstNameStatus) alertMessage += "Please specify a first name\n";
            if(!this.state.lastNameStatus) alertMessage += "Please specify a last name\n";
            if(!this.state.emailStatus) alertMessage += "Please specify a valid email address\n";
            if(!this.state.tourStatus) alertMessage += "Please select at least one tour to book (or select \"Other\")\n";
            if(!this.state.dateStatus) alertMessage += "Please specify a valid date for your tour\n";
            if(!this.state.hotelStatus) alertMessage += "Please specify a place of embarkment\n";
            if(!this.state.msgStatus) alertMessage += "Please ask us a question with at least 10 words\n"; 
            alert(alertMessage);
        } 

    }

    messageForUsCallback(textValue, status) {
        this.setState({ msgContent: textValue.trim(), msgStatus: status }); 
    }

    firstNameCallback(textValue, status) {
        this.setState({ firstNameContent: textValue.trim(), firstNameStatus: status}); 
    }

    lastNameCallback(textValue, status) {
        this.setState({ lastNameContent: textValue.trim(), lastNameStatus: status }); 
    }

    emailCallback(textValue, status) {
        this.setState({ emailContent: textValue.trim(), emailStatus: status }); 
    }

    tourCallback(tourArray, status) {
        this.setState({ toursSelected: tourArray, tourStatus: status });
    }

    hotelCallback(textValue, status) {
        this.setState({ hotelContent: textValue.trim(), hotelStatus: status });
    }

    processLocation(event) {
        if (event.target.value === "Placencia Docks (Arriving from Harvest Caye Ferry)") {
            this.setState({ hotel: false, hotelContent: "" });
        }
        else {
            this.setState({ hotel: true });
        }
    }

    render() {
        return (
            <form className="contactFormContent"
                onSubmit={this.handleSubmit}>
                <TextField for="firstName"
                           text = {true}
                           criteriaFunction={(x) => {return x.trim().length>0}}
                           placeholder="Eiluv"
                           labelText="First Name" 
                           changeFunction={this.firstNameCallback} /> 
                <TextField for="lastName"
                           text = {true}
                           criteriaFunction={(x) => {return x.trim().length>0}}
                           placeholder="Belize"
                           labelText="Last Name"
                           changeFunction={this.lastNameCallback} /> 
                <TextField for="email"
                           text = {true}
                           criteriaFunction={(x) => {return emailRegex.test(x.trim());}}
                           placeholder="jdoe@example.com"
                           labelText="Email Address"
                           changeFunction={this.emailCallback} /> 
                
                <div className="contactFormField finalField">
                    <label for="Size of Party"
                           name="sizeOfParty"
                           id="sizeOfParty"
                           required>
                        Planned Size of Party (Minimum 2)
                    </label>
                    <select className="formSelect"
                            onChange={(event) => {
                                this.setState({selectContent: event.target.value})}}>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6+</option>
                    </select>
                </div>
                <div className = "contactFormField finalField">
                    <label for="location"
                           name="location"
                           id="location"
                           required>
                        How are You Hoping to get to Placencia (Check/Select One)
                    </label>
                    <BookingTransportationGrid callbackFunction = {this.transportationCallback}
                                               hotelCallback = {this.hotelCallback}/> 
                </div> 
                <CheckboxTours tours={this.tours}
                               bundles = {this.props.bundles}
                               cruise={this.state.hotel==2}
                               cayes={this.state.hotel==3}
                               changeFunction={this.tourCallback}
                               purchase={false}/>
                <TourDate inputChange={this.dateInputChangeCallback}/>
                <MessageForUs changeFunction={this.messageForUsCallback}/>
                <SubmitButton separateComponent = "ThankYouContact" content="" clickFunction={this.handleSubmitClick}/>
            </form>
        );
    }
}

export default ContactFormContent;