import React from 'react'; 
import './../App.css';
import Title from './Title.js'; 

import '../Styles/About.css'; 

const titleText = "Safety";
const commitmentStatement = [
    "We are commited to the highest levels of safety during our tours.",
    "Major Tom, Minor Tom, and the rest of the Fun Team in Belize will take good care of you and your families and friends.",
    "Major Tom and the Fun Team have highly trained and professional guides and have always insisted on ensuring SAFETY FIRST in this adventure excursion"
]
const bullets = [
    " Every single guest MUST wear the lifevest provided, and is also given a caving light along with floatation tube for the cave tubing tour.",
    "Every guest MUST declare swimming ability, YES or NO, so we can properly prepare and protect that guest.",
    "Any disability, recent injury or operation, special medical condition, allergy whatsoever, please declare that as well.",
    "Our highly trained and experienced staff closely inspect the condition of the river and caves THE MORNING BEFORE each excursion takes place. Based on that information, we decide on carrying out the tour or not. In the event that a tour must be cancelled due to safety risks, payments made will either be refunded or tour rescheduled if applicable to guests travel dates while in Belize.",
    "This river system is NOT AFFECTED BY TIDES, only by rainfall in a particular part of the Maya Mountain Range.",
    "The various levels of water are from LOW, to MEDIUM, TO SLIGHTLY HIGH, and HIGH/NOT POSSIBLE. We have cave tubed safely in LOW, MEDIUM and SLIGHTLY high water, safely and in complete comfort, totally controlled by our guides.",
    "We both freefloat as well as link up, depending entirely on the river levels, and requests by our guests. We will, of course, decide if it is mandatory to link up, as we see the condition of the river.Linking, when not properly carried out, tends to speed up the tour, causing guests to lose the experience of the cave system, its natural beauty, special spots (such as the waterfall tumbling into the river), and important stalactite and stalagmite formations which were held sacred by Maya shamans boating through this sacred place they called their underworld (XIBALBA in Maya - pronounced SHI-BAHL-BAH). However, in freefloating, ALL guests are personally assisted by our guides, so they do not over exert themselves in any form or way. If you are linked up or not, we absolutely ensure you lose NOTHING of the quality of the tour.",
    "Families with children, especially the very young, or senior citizens, MUST link up, for safety purposes and personal confidence, even in low water conditions.",
    "If freefloating, guests are kept together by our guides, again for control, safety, and passage of information. This is one of the strongest reasons why we do not shout and howl inside the cave system (besides the safety factor of minimal noise pollution).",
    "Our ratio is ONE GUIDE per EIGHT GUESTS  maximum, although many times we do have even less numbers of guests per guide, for instance when caring for, say, a family of less than eight, particularly with children and/or senior citizens.",
    "If any guest wishes to bring along any personal gear, such as light or lifevest, they are welcome; we do have our stocks. Any gear brought by guests for personal use is subject to inspection to insure and meet safety standards required if substituted for our equpiment provided, which meets all safety standards",
    "Our operation at the caves dates back to 1992, when there was no park, no bathrooms, only a rough dirt road and uncut rainforest, with a very basic trail to the caves. We unashamedly claim pioneering background with this system, and pride ourselves in a perfect safety record, which we thank the heavens for, and work hard to maintain. Our Major Tom was instrumental in the establishment of the park and its facilities by NICH, the National Institute of Culture and History, the controlling authority at the park, and in the paving of the six mile dirt road into the park, through his efforts as President of FECTAB, the Federation of Cruise Tourism Associations of Belize. Major Tom has consistently contributed his ideas on safety and other protocol to tourism authorities in Belize, in keeping with his senior standing as a guide from 1986, and in the official and private sector tourism positions he occupied, in all that time, and continues to occupy. Major Tom, Minor Tom, and the rest of the Fun Team in Belize will take good care of you and your families and friends.",
    "Smoking and Alcoholic Beverages are NOT PERMITTED during the duration of your tour on the trail to the caves, or in the caves. After the tour has concluded, there will be time to change clothes in the bathrooms/changing facilities provided. Smoking is permitted at this time but we do ask that you dispose of cigarette butts properly so as to maintain the eco friendly environment of Belize.",
    "Finally, feel free to ask any questions whatsoever on this particular excursion, which has not been dealt with above."
    ]; 

function Safety() {
    return (
        <div className="tourBody">
            <Title text={titleText} /> 
            {commitmentStatement.map(point => 
               <div className="question">{point}</div>     
            )}
            <ol className="safetyList"> 
            {bullets.map(bullet => 
                <li className="questionBox">
                    {bullet}
                </li> 
            )}
            </ol> 
        </div>        
    );
}

export default Safety;
/*
  <script async src="https://www.jscache.com/wejs?wtype=certificateOfExcellence&amp;uniq=255&amp;locationId=1027073&amp;lang=en_US&amp;year=2019&amp;display_version=2" data-loadtrk onload="this.loadtrk=true"></script> 
  */ 