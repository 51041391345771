import React from 'react';

//props: value, past,, clickFunction, selectedDay
class MessageForUs extends React.Component {

    constructor(props) {
        super();
        this.placeholder = "We have a school trip for the first week of this July and were wondering if you could discuss possible accomodations for a large group of 50+ guests for the week";
        this.changeFunction = props.changeFunction;
        this.state = {
            value: '', status: false 
        }
    }


    render() {
        return (
            <div className="contactFormField maxWidth">
                <label for="messageForUs" required>
                    Message for us (Minimum 10 words) 
                </label> 
                <textarea id="messageForUs"
                          className={this.state.status ? "messageArea green" : "messageArea red"} 
                          name="messageForUs"
                          rows="6"
                          placeholder={this.placeholder}
                          value={this.state.value}
                    onChange={(event) => {
                              this.setState({
                                  value: event.target.value,
                                  status: event.target.value.trim().split(" ").length > 10});
                              this.changeFunction(event.target.value.trim(), event.target.value.trim().split(" ").length > 10); 
                          }}
                          required>
                </textarea> 
            </div> 
        );
    }
}



export default MessageForUs;