import React, { Component } from 'react'; 

function HotelImg({small}) {
    return (
        <div className={small ? "cruiseContainer hotelContainer small" : "cruiseContainer hotelContainer"}>
            { small ? null : 
                <div className="flag">
                    <div className="flagTop"></div>
                    <div className="flagTop"></div>
                </div>
            }
            <div className={ small ? "mayanUpperDeck small" : "mayanUpperDeck"}>
                <div className="hotelPort"></div>
            </div>
            <div className={ small ? "cruiseUpperDeck small" : "cruiseUpperDeck"}></div>
            <div className={ small ? "cruiseMiddleDeck small" : "cruiseMiddleDeck"}></div>
            <div className={ small ? "mayanBottomBar small" : "mayanBottomBar"}></div>
        </div>
    );
}

export default HotelImg;