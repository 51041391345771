
function BottomBarGrid({ bottomBarGridItems }) {
    return (
        <div className="bottomBarLogoGrid">
            {
                bottomBarGridItems.map(item => (
                    <div className="logoGridItem">
                        <div>
                            {item.logo ? item.logo : null}
                        </div>
                        <div>
                            <a href={item.url}>
                                {item.text}
                            </a>
                        </div>
                    </div>))
            }
        </div>
    );
}

export default BottomBarGrid;