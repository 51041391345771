import React from 'react';

class MoreToursBtn extends React.Component {
    constructor(props) {
        super(); 

        this.state = {
            clicked:false 
        }
    }

    render() {
        return (
            <div class="loadMoreToursBtn"
                onClick={() => {
                    this.props.clickFunction();
                    this.setState({ clicked: !this.state.clicked }); 
                }}
                id="loadMoreToursBtn">
                {this.state.clicked ? "Show Less Tours" : "Load More Tours"}
            </div>
        );
    }
}

export default MoreToursBtn;