import logo from './../logo.svg';
import './../App.css';
import DesktopNavbar from './DesktopNavbar.js';
import App from '../App.js';
import BookNow from './BookNow.js';
import AllTours from './AllTours.js'; 
import CruiseTours from './CruiseTours.js'; 
import Contact from './Contact.js'; 

import AboutGrid from './AboutGrid.js'; 
import '../Styles/About.css'; 

/*const siteUrls = [
    { url: "/Home", text: "Home", component: App },
    { url: "/About", text: "About", component: About },
    { url: "/BookNow", text: "Book Now", component: BookNow },
    { url: "", text: "All Tours", id: "desktopLand", component: AllTours },
    { url: "", text: "Cruise Tours", id: "desktopCruise", component: CruiseTours },
    { url: "/Contact", text: "Contact Us", component: Contact }
]; */ 

function About() {
    return (
        <div>
            <AboutGrid/> 
        </div>
    );
}

export default About;
