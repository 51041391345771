import React from 'react';
import TourCard from './TourCard.js';

function TourGrid({ bookTourFunction, tours, omit, filter, reducedHeight, bundles }) {
    return (
        <div>  
            {bundles ?  
            <div className= "grid-container-bundle">
                {bundles.map(bundle => (
                    <div className="bundleRow"> 
                    {bundle.tours.map(bundleTour => (
                        <TourCard  bookTourFunction = {bookTourFunction} tour = {tours.find(desc=>desc.name === bundleTour)} bundle = {true}/>
                    ))}
                        <div> 
                            <div></div> 
                            <div>Price Per Adult: {bundle.price}</div> 
                            <div>Price Per Child{bundle.priceChild}</div>
                        </div> 
                    </div> 
                ))}
            </div> 
            : 
            <div className={reducedHeight != null && reducedHeight ? "grid-container" : "grid-container maxed"}
            id="gridImages">
            {tours.map(tour => (
                tour.name === omit ? null : 
                filter!=null && filter==="cruise" && !tour.cruise ? null : 
                <TourCard tour={tour} bookTourFunction = {bookTourFunction}/>
            ))}
            </div>} 
        </div>
    );
}

export default TourGrid;