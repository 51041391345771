import React from 'react';
import CruiseImg from './CruiseImg'; 
import HotelImg from './HotelImg'; 

class TourBundleGrid extends React.Component{
    constructor(props){
        super(); 
    }
    //this.options = ["Hotel Visitors", "Caye Visitors", "Cruise Passengers"]; 


    render(){
        return(
            <div> 
                {
                    this.props.bundles.map(bundle => (
                        this.props.filter === "Caye Visitors" && !bundle.cayes ? null :
                        this.props.filter === "Cruise Passengers" && !bundle.cruise ? null :  
                        <div className="tourBundleGrid">
                            <div>
                                <div className="tourBundleGridCentered" onClick = {()=>{this.props.clickBundleFunction(bundle.tours);}}>
                                    <div> 
                                        <div>{bundle.name}</div> 
                                        <div className="tourBundleGridPrice">${bundle.price} Per Person</div>
                                        <div className="tourBundleGridSavings">Save $ {bundle.savings} per person</div>  
                                    </div> 
                                    <div className = "tourBundleButtonContainer">
                                        <div className="tourBundleButton">Book This Bundle</div> 
                                    </div> 
                                </div>
                                <ol className="tourBundleItems">{bundle.tours.map(tour =>(
                                    <li className="tourCardBundleItem">
                                        <div className="tourCardBundle" 
                                             style={{backgroundImage: `url(${this.props.tours.find(o=>o.name===tour).cardImage})`}}>
                                            <a className="tourBundleGridLink"
                                               href={this.props.tours.find(o => o.name===tour).url}>
                                                {this.props.tours.find(o => o.name===tour).text}
                                            </a>
                                            <a href = {this.props.tours.find(o => o.name===tour).url} className = "seeTour">View this Tour</a>
                                        </div>
                                    </li>))}
                                </ol>
                            </div>
                            <div className="bundleLogoGrid">
                                <div className="bundleLogo label">
                                    <div className = "bundleLogoTextContent"> 
                                    This Bundle is Available for Passengers coming to Placencia via:</div> 
                                </div>
                                <div className={this.props.filter === "Hotel Visitors" ? "bundleLogo chosen" : "bundleLogo"}>
                                    <HotelImg small = {true}/>
                                    <div className="bundleLogoItem">Hotel Visitors</div>
                                </div>
                                {bundle.cruise ? 
                                <div className={this.props.filter === "Cruise Passengers" ? "bundleLogo chosen" : "bundleLogo"}>
                                    <CruiseImg small = {true}/>
                                    <div className="bundleLogoItem">Cruise Passengers</div> 
                                </div>
                                : <div className="bundleLogo empty"></div> 
                                }
                            </div> 
                        </div> 
                    ))
                }
            </div>
        );
    }
}
export default TourBundleGrid; 