import GridOptionItem from './GridOptionItem.js'; 

function TransportationOptions({transportationOptions, small})
{
    return (
        <div className="optionsGrid">
            {
                transportationOptions.map(option => (
                    <GridOptionItem option = {option}/> 
                ))
            }
        </div>
    );
}

export default TransportationOptions;