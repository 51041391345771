import logo from './../logo.svg';
import './../App.css';
import DesktopNavbar from './DesktopNavbar.js';
import About from './About.js'; 
import App from '../App.js';
import BookNow from './BookNow.js';
import AllTours from './AllTours.js';
import Contact from './Contact.js';
import TourGrid from './TourGrid.js'; 

const siteUrls = [
    { url: "/Home", text: "Home", component: App },
    { url: "/About", text: "About", component: About },
    { url: "/BookNow", text: "Book Now", component: BookNow },
    { url: "", text: "All Tours", id: "desktopLand", component: AllTours },
    { url: "", text: "Cruise Tours", id: "desktopCruise", component: CruiseTours },
    { url: "/Contact", text: "Contact Us", component: Contact }
];

function CruiseTours({ tours } ) {
    return (
        <div>
            <div className="homeBodyContainer" >
                <div className="homeTitle">Set Anchor and Discover</div>
                <div className="introText">
                    Looking to start an adventure in Placencia for a day while your cruise
                    ship sets anchor?
                </div>
                <div className="introText">
                    Norweigan Cruise Lines drops off passengers on their private island of 
                    Harvest Caye, where you will take the Harvest Caye express ferry to the 
                    Placencia docks.
                    We highly recommend that you book your ferry ticket in advance, 
                    and that you book an earlier time as often the ferries that leave later on 
                    in the morning can reach capacity.
                </div>
                <div className="introText">
                    We will have our experienced tour guides pick you up from the Placencia Docks, 
                    the direct destination of the Harvest Caye express, to start your comfortable,
                    safe, and timely excursion.
                    From the Placencia Docks, we will accompany you through your tours,
                    which are selected in locations where you will have the most time for 
                    your experience, while ensuring we can get you back to the cruise on time.
                </div>
                <div className="introText">
                    The most popular ways to experience the full beauty of Belize's untouched
                    broadleaf rainforest is through our natural waterfalls and Spice Farm tropical
                    botanical gardens tours.
                    In all of these tours, you will see some of the most breath-taking natural 
                    wonders on the planet and will be treated to an authentic, fresh Belizian meal.
                    To see ancient Mayan stone tablets and basketball courts, 
                    visit the Nim Li Punit Mayan ruins, one of the youngest and most well-preserved
                    Mayan sites.
                </div>
                <div className="introText">
                    Note that all tours for Norweigan Cruise Lines passengers are also available for guests staying in hotels in the Placencia area. 
                 </div> 
                <TourGrid tours={tours} filter="cruise" />
            </div>
        </div>
    );
}

export default CruiseTours;
