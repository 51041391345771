import React, { Component } from 'react'; 
import logo from './../logo.svg';
import './../App.css';
import DesktopNavbar from './DesktopNavbar.js';
import App from '../App.js';
import AllTours from './AllTours.js'; 
import CruiseTours from './CruiseTours.js'; 
import Title from './Title.js'; 

import AboutGrid from './AboutGrid.js'; 
import '../Styles/About.css'; 

const titleText = "Cave Tubing Covid Information";

const safety = <div>Please see our full safety guidelines <a className = "prettyLink"href="/safety">here</a>.</div>;
const questions = [
];

function Covid() {
    return (
        <div className="tourBody">
                <Title text={titleText} /> 
                {questions.map(question => 
                    <div className="questionBox">
                        <div className="question"> {question.question}</div> 
                        <div className="answer"> {question.answer}</div> 
                    </div> 
                )}
        </div>
    );
}

export default Covid;