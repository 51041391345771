import imgSrcVar from './../images/general/companyLogoDesktopMobile.webp';
import DropdownArea from './DropdownArea.js'; 

import React from 'react';

class DesktopNavbar extends React.Component {

    constructor(props) {
        super(); 

        this.props = props;
    }

    render() {
        return (
            <nav className="mobileNavbar">
                <DropdownArea siteUrls={this.props.siteUrls}
                              tours={this.props.tours} /> 
                <div className="navText">Placencia Action Tours</div> 
                <a className="companyLogoContainer"
                   href="/">
                    <img id="companyLogoMobile"
                         alt="Placencia Action Tours Quetzcoatl Mascott"
                         src={imgSrcVar}/> 
                </a> 
            </nav>
        );
    }
}

export default DesktopNavbar;