import React from 'react';
import { useHistory } from 'react-router';
import { PayPalButton } from "react-paypal-button-v2";  //Use of paypal button 

//const paypalId = "AZEG07CadJU1ssSNdXVLtOMFWY6UTVjzPQdKQr1aPHDyUYH5ES6EjQZ5x_F5LJ6_Ec4Co1P5uTT60x6b"; 

function PPBtn({totalPrice, clickFunction}){
    let history = useHistory(); 
    return (
        <div className="payPalButtonContainer">
                <PayPalButton
                                    amount={totalPrice * .1}
                                    currency="USD"
                                    catchError={() => { 
                                        console.log("Error processing transaction");
                                        alert("error processing transaction"); 
                                     }}
                                        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                    onSuccess={(details, data) => {
                                        //alert("Transaction completed by " + details.payer.name.given_name);
                                        console.log(data.id); 
                                        let ret = clickFunction(data);
                                        if(ret)
                                        {
                                            history.push("/ThankYou"); 
                                        }
                                        console.log(details, data); 
                                        // OPTIONAL: Call your server to save the transaction
                                        /*return fetch("/paypal-transaction-complete", {
                                            method: "post",
                                            body: JSON.stringify({
                                                orderID: data.orderID
                                            })
                                        });*/
                                    }}
                                    options={{
                                        //PayPal Production ID below 
                                        clientId: "AXFBzZT5FwFxn1j8TSZNahUulbfaE0udDoMOs7vm1kESvY16x3MmLVlGs-3hbAy4iEolZKOhyAs6dypF"
                                        //PayPal Sandbox ID below
                                        //clientId: "AZEG07CadJU1ssSNdXVLtOMFWY6UTVjzPQdKQr1aPHDyUYH5ES6EjQZ5x_F5LJ6_Ec4Co1P5uTT60x6b" 
                                    }}

                />
            </div>
        );
}

export default PPBtn;