import React from 'react';


class TotalCost extends React.Component {

    constructor(props) {
        super();
    }

    render() {
        return (
            <div> 
                <div className="contactFormField">
                    <label for="totalCost"
                           name="totalCost"
                           id="totalCost">
                        Total Cost:
                    </label> 
                    ${this.props.value}USD
                    ${2*this.props.value}BZE
                </div>
                <div className="contactFormField">
                    <label for="depositCost"
                        name="depositCost"
                        id="depositCost">
                        Tour Desposit Cost (paid now):
                    </label>
                    ${.1*this.props.value}USD
                </div>
                <div className="contactFormField">
                    <label for="payToGuideCost"
                        name="payToGuideCost"
                        id="payToGuideCost">
                        Remaining Paid to Guide on Tour:
                    </label>
                    ${.9 * this.props.value}USD
                    ${1.8 * this.props.value}BZE
                </div>
            </div >
        );
    }

}

export default TotalCost;  
