import React, { Component } from 'react';   //Use of class-based react components


import logo from './../logo.svg';
import './../App.css';
import DesktopNavbar from './DesktopNavbar.js';
import About from './About.js'; 
import App from '../App.js';
import BookNow from './BookNow.js';
import CruiseTours from './CruiseTours.js';
import Contact from './Contact.js';

import '../Styles/Tour.css';
import Title from './Title.js';
import LongDescription from './LongDescription.js';
import LogisticsGrid from './LogisticsGrid.js';
import PricingBox from './PricingBox.js';
import ImageSlideDeck from './ImageSlideDeck.js';
import TourGrid from './TourGrid.js'; 
/* 

class AllTours extends React.Component{

    constructor(props) {
        super(); 
        this.props = props; 
    }

    render() {
        return (
            <div>

            </div>
        );
    }

}
*/

function AllTours({ tours }) {
    return (
        <div className="homeBodyContainer" >
            <div className="homeTitle">All Tours</div> 
            <div className="introText">
                If you are looking to start an adventure while staying in the Placencia area,
                you will have a wide selection of outstanding and unique experiences where you 
                can select your niche.
            </div> 
            <div className="introText">
                Our professional guides will pick you up right from your hotel door, 
                where we will accompany you through the sites, and wherever Belize takes you.
            </div> 
            <div className="introText">
                If you decide that you want to add a stop throughout your journey, we will be 
                happy to accomodate and to give you the best experience Belize offers. 
                If you have an experience in mind that is not listed in this section, 
                please feel free to contact us and we will look into it for you.
            </div> 
            <div className="introText">
                Interested in uncovering the mysteries of the ancient Mayans?
                Our guides can take you to the Mayan ball court in Nim Li Punit, 
                the fortress of Lubaantun, and to the sacrificial ATM Caves.
            </div> 
            <div className="introText">
                Belize is home to some of the most beautiful natural reserves, 
                where water carves tropical subterranean streams and waterfalls through the limestone rocks.
                Our cave tubing, waterfall sightseeing, South Staan Creek jungle river tubing, 
                and inland blue hole feature these breathtaking natural wonders.
                The Spice Farm tropical botanical garden, Belize Zoo, 
                and Rainforest Ziplining all will showcase the magical natural life the broadleaf rainforests host.
            </div> 
            <div className="introText">
                Cooling off in the turqoise Caribbean waters is a must for visitors.
                Snorkel with the fish, or even reel some in for a barbeque with our South Water Caye tours.
                Or perhaps, rent a private plane for premier views of the Great Blue Hole
                - a bucket list natural wonder. If a day is not enough for you,
                plan out your own adventure and charter our boat "Changes in Attitude" to explore whatever cayes you fancy.
            </div> 
            <TourGrid tours={tours}/> 
        </div>
    ); 
}
export default AllTours;  
/*                <div className="tourBody">
                    <Title text="All Tours"/>
                    <LongDescription content=""asdf />
                    <TourGrid tours={this.props.tours} omit={this.props.tours[0].name} />
                </div>*/