import React from 'react';



class Title extends React.Component {

    constructor(props) {
        super();
    }



    render() {

        return (
            <div className="homeTitle"> 
                {this.props.text}
            </div> 
        );
    }
}

export default Title;