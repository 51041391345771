import ContactFormContent from './ContactFormContent.js'; 

function ContactForm({ contactFields, tours }) {
    return (
        <div className="contactForm">
            <ContactFormContent contactFields={contactFields} tours={tours}/> 
        </div>
    );
}

export default ContactForm;
