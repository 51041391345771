import React, { useState } from 'react';

const daysInMonth = {
    1: 31,
    2: 28,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31
}; 

//props: value, past,, clickFunction, selectedDay
class Day extends React.Component {

    constructor(props) {
        super(); 
        this.date = new Date();
        this.clickFunction = props.clickFunction; 
        this.past = props.past; 
        this.value = props.value; 
        this.show = true; 
        this.past = props.currMonth==this.date.getMonth() && this.date.getDate() >= this.value; 
    }


    selectSelf() {

    }


    render() {
        this.past = this.props.currMonth === this.date.getMonth() && this.date.getDate() >= this.value; 
        if (daysInMonth[this.props.currMonth % 12 + 1] >=this.value)
            return (
                <div className={this.past ? "day past" : this.props.selectedDay ? "day selected" : "day"}
                     value={this.value}
                     onClick= {this.show ? (event) => { this.clickFunction(this.value); } : null}>
                        {this.value}
                </div>
            );
        else return null; 
    }
}



export default Day;