import React, { Component } from 'react';   //Use of class-based react components
import GridOptionsShowToggle from './GridOptionsShowToggle.js'; 

class GridOptionItem extends Component{

    constructor(props){
        super();
        this.state = {
            show: false
        }
        this.toggleCallback = this.toggleCallback.bind(this);  
        this.initialSentence = props.option.description[0]; 
        this.small = props.small && props.small === true; 
    }


    toggleCallback()
    {
        let toggle = this.state.show; 
        this.setState({show:!toggle});
    }

    render(){
        return (
            <div className={this.state.show ? "gridOption full" : "gridOption"}>
                <h2 className="OptionTitle">{this.props.option.title}</h2>
                {this.props.option.imgComponent(false)}
                <div className="OptionText">
                    <p className="homeTextBlock">{this.initialSentence}</p>
                </div> 
                <div className={this.state.show ? "OptionText" : "OptionText short"}>
                    {this.props.option.description.map(
                        desc => (<p className="homeTextBlock">{desc}</p>))}
                </div> 
                    <GridOptionsShowToggle toggled = {this.state.show} clickEvent = {this.toggleCallback}/> 
            </div> 
        ); 
    }
}

export default GridOptionItem; 