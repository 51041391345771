import PropTypes from 'prop-types';
import TripAdvisorWidget from './TripadvisorWidget';

function DesktopNavbarText({ text}) {
    return (
        <div className="desktopNavbarText">
            {text}
            <div className="navbarTripadvisorContainer">
                <TripAdvisorWidget/>
            </div>
        </div>
    );
}

DesktopNavbarText.propTypes = {
    text: PropTypes.string
}

DesktopNavbarText.defaultProps = {
    text: ""
}

export default DesktopNavbarText;