function AboutText() {
    return (
        <div className="aboutText">
            <div className="homeTitle">About Us</div>
            <p>
                Placencia Action Tours is your number one choice in tour operations when visiting the Placencia peninsula. We offer tours in the southern region of Belize and with sister companies all across the country we are the most viable choice for reaching any and all of your vacation goals while visiting Beautiful Belize.
            </p>
            <h3 class="aboutTitle">
                The Best Guides Belize Offers
            </h3>
            <p>
                We were founded as a family business from the first tour guides in Belize. All of our guides are friendly, experienced and licensed professionals who are very knowledgable of the country and its history. We love showing people the beauty that Belize has to offer, and teaching people the rich heritage of the sites.
            </p> 
            <h3 class="aboutTitle">
                Bring the Whole Family
            </h3> 
            <p>
                We offer a variety of inland, sea and air tours and with our courteous and professional guides at your full disposal. Our guides have experience touring both small and large groups with sites all throughout the country: if you are looking for a particular experience that is not listed on the site, or have a larger group, please do not hesitate to contact us.
            </p>
            <h3 class="aboutTitle">
                Kick Back and Enjoy the Ride
            </h3> 
            <p>
                All of our tours will be comfortable, fun, and relaxing. Our guides will pick you up from Placencia Dock if you are coming from a cruise, or from your hotel if you are staying in Placencia. Rides will be smooth, safe and air-conditioned.
            </p> 
            <div className = "bottomMargin"></div>
        </div>
    );
}

export default AboutText;