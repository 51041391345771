import React from 'react';
import { withRouter } from 'react-router-dom';

class PricingBox extends React.Component {

    constructor(props) {
        super();
    }

    render() {

        return (
            <div className="pricingBox">
                <h3 className="OptionTitle">Pricing and Logistics</h3>
                { this.props.cruise ? 
                  <div>This tour can be booked by guests arriving via Norweigan Cruise lines tender to Harvest Caye. Note that from Harvest Caye guests must book the Harvest Caye Ferry to Placencia Docks. </div>
                : <div>This tour cannot be booked by guests arriving via Norweigan Cruise lines tender to Harvest Caye due to time constraints</div>}
                <div>This tour can be booked by guests residing in a hotel in Placencia Area</div>
                { this.props.price && this.props.price > 0 ?
                <>
                    <div>This tours costs ${this.props.price} USD per adult. This can also be payed as ${this.props.price * 2} BZE per adult.</div>
                    <div>Children under 5 are free!</div>
                    <div>Book your spots for this tour now to pay a 10% booking deposit of ${this.props.price * .1} USD/adult and pay the rest to your guide when the tour begins. Booking deposits are paid through a secure PayPal portal.</div> 
                 </>
                : 
                <div>
                    Please contact us to inquire about pricing for this tour. This can be done through filling out our <a href="/Contact">contact form</a>, emailing us at placencia.action.tours@outlook.com, or reaching out through WhatsApp: +5016374477
                </div>
                }
                <div>Please do not hesitate to contact us about rates for large groups.</div>
                <div className = "imageCardBtn"  onClick = {(event) => 
                {if(this.props.bookTourFunction != null && this.props.name != null )
                {
                    if(this.props.price && this.props.price > 0) {
                        this.props.bookTourFunction([this.props.name]);
                    } else {
                        const { history } = this.props;
                        history.push('/Contact');
                    }
                }}}>
                    {this.props.price && this.props.price > 0 ? "Book This Tour" : "Contact Us"}
                </div>
            </div>
        );
    }
}

//If we want to re-enable child-pricing for this site just uncomment this and add it back into the jsx above!
//<div>This tours costs ${this.props.priceChild} USD per child ages 5-11. This can also be payed as ${this.props.priceChild * 2} BZE per child.</div> 


export default withRouter(PricingBox);