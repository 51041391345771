import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useHistory } from 'react-router-dom'; 

import './App.css';
import DesktopNavbar from './Components/DesktopNavbar.js';
import Home from './Components/Home.js'; 
import About from './Components/About.js';
import BookNow from './Components/BookNow.js';
import AllTours from './Components/AllTours.js';
import Contact from './Components/Contact.js';
import CruiseTours from './Components/CruiseTours.js';
import CruiseImg from './Components/CruiseImg.js';
import Footer from './Components/Footer.js';
import HotelImg from './Components/HotelImg.js';
import MobileNavbar from './Components/MobileNavbar.js'
import FacebookIcon from './Components/FacebookIcon.js';
import InstagramIcon from './Components/InstagramIcon.js';
import TourBody from './Components/TourBody.js'; 
import TripadvisorIcon from './Components/TripadvisorIcon.js';
import ThankYou from './Components/ThankYou.js';

import Covid from './Components/Covid.js';
import FAQ from './Components/FAQ.js';
import Safety from './Components/Safety.js';
import ThankYouContact from './Components/ThankYouContact.js';

import HomeIcon from '@mui/icons-material/Home';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import DirectionsBoatOutlinedIcon from '@mui/icons-material/DirectionsBoatOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CallIcon from '@mui/icons-material/Call';

//Begin importing grid images
//@TODO: This is currently hard-coded, there may be a more efficient way
//@TOOD: These images are a mixture of png and jpg, we may want to convert to an optimal file format
import nimLiImage from './images/nimLiSquare.webp';
import waterfallImage from './images/waterfalls.webp';
import zipliningImage from './images/ziplining.webp';
import spiceFarmImage from './images/spiceFarm.webp';
import lubaantunImage from './images/lubaantun.webp';
import riverTubingImage from './images/riverTubing.webp';
import inlandBlueHoleImage from './images/inlandBlueHole.webp';
import blueHoleImage from './images/blueHole.webp';
import snorkelImage from './images/snorkel.webp';
import fishingImage from './images/fishing.webp';
import atmImage from './images/atm.webp';
import caveTubingImage from './images/caveTubing.webp';
import twinCitiesImage from './images/twinCities.webp';
import zooImage from './images/zoo.webp';
import yachtImage from './images/yacht.webp';


function importAll(r) {
    let ret = []; 
    let i = 0; 
    r.keys().map((item, index) => { ret[item.replace('./', '')] = r(item); ++i });
    ret["numKeys"] = i; 
    return ret; 
}

const siteUrls = [
    { logo: <HomeIcon/>, url: "/", text: "Home", component: App },
    { logo: <InfoOutlinedIcon/>, url: "/About", text: "About", component: About},
    { logo: <ConfirmationNumberOutlinedIcon/>, url: "/BookNow", text: "Book Now", component: BookNow},
    { logo: <MenuOutlinedIcon/>, url: "/tours/AllTours", text: "All Tours", id: "desktopLand", component: AllTours },
    { logo: <DirectionsBoatOutlinedIcon/>, url: "/tours/CruiseTours", text: "Cruise Tours", id: "desktopCruise", component: CruiseTours},
    { logo: <ContactPageOutlinedIcon/>, url: "/Contact", text: "Contact Us", component: Contact},
    { logo: <CallIcon/>, text: "WhatsApp: +5016374477", },
    { logo: <EmailOutlinedIcon/>, text: "placencia.action.tours@outlook.com",}


];

const followUs = [
    { alt: "Facebook", imgComponent: FacebookIcon, url: "https://www.facebook.com/pages/category/Tour-Agency/Placencia-Action-Tours-537314880112426/" },
    { alt: "Instagram", imgComponent: InstagramIcon, url: "https://www.instagram.com/placencia_action_tours/" },
    { alt: "Tripadvisor", imgComponent: TripadvisorIcon, url: "https://www.tripadvisor.com/Attraction_Review-g291977-d20387654-Reviews-Placencia_Action_Tours-Placencia_Stann_Creek.html" }
]; 

//Variables to shorten the content for nimLiPunit
const nimLiContent = ["Nestled between the lush broadleaf rainforest and the the foothills of the Maya mountains, resting between clear mountain streams lies Nim Li Punit. This site is very well-preserved as the relatively high population flourished at its peak around 500 AD.",
                      "Nim Li Punit is well-known for the large amount of stelae (stone tablets) commemorating the ancient royalty, culture, and general records of the site. You will also see an astonishingly well-preserved Mayan basketball court near the plaza of this ancient city."];
const nimLiIncludes = ["An authentic Belizean meal",
                       "Comfortable, air-conditioned transport",
                       "Water"]; 
const nimLiWhatToBring = ["A good pair of hiking/athletic shoes",
                          "Sunscreen",
                          "Bug spray"];

//Variables to shorten the content for the waterfalls tour
const waterfallsContent = ["Located on the southeast coast of southern Belize are the beautiful Stan Creek Hills the home to beautiful havens like this. Bask under the beautiful southern water falls and enjoy some good family fun. Rum punch is an option!"]; 
const waterfallsWhatToBring = ["Clothes and shoes you are comfortable with getting wet",
                               "A Change of clothes",
                               "Towel",
                               "Sunscreen",
                               "Bug spray"];

//Variables to shorten the content for the ziplining tour
const zipliningContent = ["Set within the Southern foot hills of the beautiful Maya Mountain Range is the awesome ziplining experience at Maya Sky Canopy. With a total of 6 platforms the highest of which being over 400 feet high and 1000 feet across, you are sure to have a blast! Guests can expect to travel at speeds of up to 38 miles per hour through the lush rainforest."]; 
const zipliningIncludes = ["An authentic Belizean meal",
                           "Comfortable, air-conditions transport",
                           "Water",
                           "Ziplining gear and safety helmet"];
const zipliningWhatToBring = ["A good pair of hiking/athletic shoes",
                              "Sunscreen",
                              "Bug spray",
                              "Courage of the Jaguar"];


//Variables to shorten the content for the spice farm tour
const spiceFarmContent = ["Between the foothills of the Maya Mountains and the broadleaf rainforest lies this showcase of Belize's natural botanicals. Here you will get to go on a botanical tour and get a chance to see, touch, smell and taste a variety of tropical plants,fruits and herbs. This is topped off with an authentic Belizean meal at the beautiful spice farm dining room."];

//Variables to shorten the content for the lubaantun tour
const lubaantunContent = [
    "Welcome to Lubaantun, an ancient Maya city located in the southern part of Belize! This tour will take you on a journey through time, exploring the rich history and culture of the Maya people.",
    "Our adventure begins at the entrance of Lubaantun, where you will be greeted by a knowledgeable guide who will take you through the site's most important landmarks. As you make your way through the ruins, you'll learn about the intricate architectural details of the various buildings and structures that once stood tall in this city.",
    "Also known as \"The Place of the Fallen Stones,\" Lubaantun is well known for the huge difference in architectural design as in comparison to the other sites found in the rest of Belize. The pyramids here are built of black slate with no mortar, rather than the typical limestone blocks - it resembles more of an ancient military stronghold than a city.",
    "You'll have the opportunity to see the impressive ball court, where the Maya played their famous ball game, and the ceremonial center where they conducted religious rituals. You'll also visit the residential areas where the common people lived and worked, as well as the palace where the Maya rulers resided.",
    "One of the highlights of the tour is the opportunity to explore the unique and enigmatic Lubaantun crystal skull discovered in these ruins. This mysterious artifact has been the subject of much speculation and intrigue. The skull is rumored in the pseudo-science community to have ancient mayan mystical powers as a bringer of both doom and enlightenment. On this tour you'll have the chance to learn more about its fascinating history.",
    "Throughout the tour, your guide will share stories and legends of the Maya people, their beliefs, and their way of life. You'll gain a deep understanding of the culture and society that flourished in Lubaantun more than a thousand years ago.",
    "This tour is perfect for history buffs, culture enthusiasts, and anyone who wants to experience the magic and mystery of ancient civilizations. Don't miss this opportunity to explore one of the most important and impressive Maya sites in Belize. Book your Lubaantun tour today!",
];

//Variables to shorten the content for jungle river tubing tour
const jungleTubingContent = ["River rafting on inner tubes through the foothills of beautiful southern Belize on a crystal clear riverine system through dense, tropical, broadleaf rainforest followed by an authentic delicious Belizean coconut rice & beans meal."];
const jungleTubingWhatToBring = waterfallsWhatToBring; 

//Variables to shorten the content for the inland blue hole tour 
const inlandBlueHoleContent = ["The Mayan shamans used these series of amazing cave systems for their religious ceremonies as they believed this was the ancient dwelling place of their dark lords. The underworld known as Xibalba translated into hell must be traversed by the soul in order to ascend into heaven.",
                               "In the modern age we explore these caves and learn and appreciate all about the mayan culture. We top it off with a swim in the natural pool created by a sinkhole in the side of the mountain known as the inland blue hole."]; 
const inlandBlueHoleWhatToBring = waterfallsWhatToBring; 

//Variables to shorten the content for the blue hole flyover tour 
const blueHoleContent = ["Measuring 1,000 feet across and approximately 450 feet deep, it is the largest blue hole in the ocean. Passengers are expected to take an approximate one hour flight in which they will get to make multiple passes over the majestic blue hole and gaze at the natural wonder.",
                         "The blue hole was formerly an above ground cavern but when water receded due to geological shifts millions of years ago the roof of the cavern caved in forming today what we know as the blue hole. Through this process the deepest sink hole in the western hemisphere was formed.",
                         "The Great Blue Hole is at the top of bucket lists not only for its natural beauty from above, but for scuba diving exploration. If you are a certified scuba diver with intermediate experience, do not hesitate to contact us and we will arrange it so that you can explore this natural wonder from the inside."];
const blueHoleIncludes = ["Comfortable, air-conditioned transport",
                          "A private flight for your group"];
const blueHoleWhatToBring = ["Camera"]; 

//Variables to shorten the content for the Caribbean snorkeling tour 
const snorkelingContent = ["Nestled in the tropical islands with turqoise blue water lapping the white sandy beaches and the cool Caribbean breeze rustling the palm trees, lies South Water Caye. The island crowns an expansive coral reef within its very own South Water Marine Reserve, it is one of Belize's most stunning destinations.",
                           "Passengers taking this excursion will be picked up from wherever they are staying in the Placencia area and transferred to the charming beach town of Dangriga via tour vehicle. Once you reach the town, you will transfer to a boat and be taken on a snorkeling excursion throughout the incredible South Water Marine Reserve. You can expect to see some of the most beautiful tropical reef that the world has to offer. Your breath will be taken away by the colorful coral, wide variants of tropical fish, conch, lobsters, eels, and even sea turtles or manatees.",
                           "A delicious authentic Belizian Barbeque Lunch will be provided. After you have eaten, you have the choice to either stay and Lounge at the beach. If you change your mind while lounging, note that the coral reef is an easy, safe swim from there."];
const snorkelingIncludes = ["Air-conditioned transport from wherever you are staying in Placencia to Dangriga",
                            "A boat ride to optimal snorkel locations throughout the South Water Marine Reserve",
                            "Snorkel masks, fins, other snorkel gear", 
                            "A delicious Belizian barbeque at one of the most beautiful beaches in the world"];
const snorkelingWhatToBring = ["Sunscreen", "Towel", "Change of Clothes", "Swimsuit", "A GoPro or waterproof camera for underwater photos"];

//Variables to shorten the content of the Caribbean fishing tour 
const fishingContent = ["Nestled in the tropical islands with turqoise blue water lapping the white sandy beaches and the cool Caribbean breeze rustling the palm trees, lies South Water Caye.",
                        "Passengers taking this excursion will be picked up from wherever they are staying in the Placencia area and transferred to the charming beach town of Dangriga via tour vehicle. Once you reach the town, you will transfer to a boat and be taken on a fishing excursion through the Caribbean waters near South Water Caye.",
                        "Note that all fishing equipment will be provided, however we encourage you to bring your own rig if you are interested!",
                        "South Water Caye fishing is unique in that you will have the opportunity to do shallow reef water fishing along with deeper blue sea fishing. You will be able to do troll fishing, bait casting, deep sea fishing, and even Belizian-style line fishing! The local waters are teeming with varieties of snapper, tarpon, snook, and many other varieties of fish. Seasonally, there may also be lobster, conch, and many other catchable critters.",
                        "When you head back, your fishing captain or tour guide will clean and fillet your fresh catch-of-the-day because it will be time to start up a celabratory barbeque. For most fishing excursions, this will be an all-you-can-eat endeavor. After you have eaten, you can lounge at the beach. When you are finished lounging, you will take the boat back to Dangriga docks, where your guide will transport you back to where you are staying."];
const fishingIncludes = ["Air-conditioned transport from wherever you are staying in Placencia to Dangriga",
                         "A boat ride through the Southwater Marine Reserve from Dangriga",
                         "A boat ride to optimal fishing locations throughout the South Water Marine Reserve",
                         "Fishing equipment & tackle",
                         "A fresh fish barbeque of the catch of the day"];
const fishingWhatToBring = ["Towel",
                            "Sunscreen",
                            "Your own fishing gear (optional)",
                            "Swimsuit",
                            "A GoPro or waterproof camera",
                            "Strong wrists and forearms!"];

//Variables to shorten the content of the ATM Cave Tour 
const ATMContent = ["Known as the place of the stone sepulchre. This site is an ancient underground Maya burial site. A hiking and adventure experience not for the faint of heart. This is no leisure tour.",
    "Ranked as one of the top ten caves in the world by National Geographic Society you are sure to be amazed setting your feet in the same place that ancient mystical Mayan Shamans once performed sacrifices to their Old Ancestral gods"]; 

//Variables to shorten the content of the Cave Tubing Tour
const caveTubingContent = ["Located in the centre of the country of Belize, this cave system is located within a National Park it is home to hiking trails through the rainforest after which you take a leisurely float on inner tubes through a 1.5 mile long cave system with a river running through them.",
    "Natural limestone formations, archaeological history of the Maya and good old family fun and relaxation are all part of this wonderful cave exploration experience."]; 

//Variables to shorten the content of the Twin Cities Tour
const twinCitiesContent = ["Located in the Western most reaches of Belize are the two mystical and ancient Maya sites of Xunantunich and Cahal Pech. These two structures date back to the Classic period of the Maya and represents the grandeur of the Maya civilization whilst they were at their highest height. Learn about the history of the Maya and the archaeological, astronomical and architectural wonders performed by these Palaeolithic people. Guests will also get to enjoy the beautiful surrounding of the lush rain forests of Belize."];

//Variables to shorten the content of the Belize Zoo Tour 
const belizeZooContent = ["In the mid 1980�s Sharon Matola and a team of American, Canadian and British animal conservationist came to Belize to do a documentary on tropical exotic species of animals.",
    "In their efforts they acquired animals that have no business being kept as pets amongst them: Jaguars, boa-constrictors, tapirs, birds of prey, and a melee of other exotic animals. After the movie production Sharon was left with the difficult task of finding a home for these exotic creatures. It is with this effort she made the Belize Tropical Education center.",
    "To this day Sharon is the only person in Belize allowed to take care of wild animals. In fact the only animals located at the zoo are animals that cannot be rehabilitated and returned to the wild. All 5 of Belize�s exotic cats are found at the Belize Zoo. Also the beautiful Toucan, the Scarlett Macaw and over 40 other Tropical species of snakes, birds, fish and mammals. A perfect place to see what Belize rainforest holds."];

//Variables to shorten the content of Caribbean Yacht Charter Tour 
const yachtContent = ["This 150ft behemoth of a boat has some of the best amenities that some people wish to have in their home. With a full living area a top deck lounge and two air conditioned bedrooms on the bottom deck the passengers riding in this bad boy are sure to feel like royalty, especially as we travel to some the most exclusive private islands and coral reef wildlife reservoirs of Belize.",
                      "Guests can either select from one of our pre-determined destinations or go wild with it and pick wherever they want to go. We invite you to consider this as your way to see beautiful Belize as we host you guys on what is the largest barrier reef this side of the world.",
                      "Email for inquiries and quotes. Have some fun in the sun!"];

let tours = [
    { priceChild: 0, gridImages: importAll(require.context('./images/nimLi/grid', false, /\.(webp)$/)), slideImages: importAll(require.context('./images/nimLi', false, /\.(webp)$/)), whatToBring: nimLiWhatToBring, includes: nimLiIncludes, content: nimLiContent, price: 120, name: "nimLi", cardImage: nimLiImage, url: "/tours/NimLiPunit", text: "Nim Li Punit Mayan Ruins", cruise: true, ShortDesc: "Seeing how the ancient Mayans played basketball? The ball is in your court." },
    { priceChild: 0, gridImages: importAll(require.context('./images/waterfalls/grid', false, /\.(webp)$/)), slideImages: importAll(require.context('./images/waterfalls', false, /\.(webp)$/)), whatToBring: waterfallsWhatToBring, includes: nimLiIncludes, content: waterfallsContent, price: 100, name: "waterfalls", cardImage: waterfallImage, url: "/tours/Waterfalls", text: "Waterfall Sightseeing", cruise: true, ShortDesc: "Stroll through natural waterfalls in the rainforest with a picnic at the end." },
    { priceChild: 0, gridImages: importAll(require.context('./images/ziplining/grid', false, /\.(webp)$/)), slideImages: importAll(require.context('./images/ziplining', false, /\.(webp)$/)), whatToBring: zipliningWhatToBring, includes: zipliningIncludes, content: zipliningContent, price: 130,name: "ziplining", cardImage: zipliningImage, url: "/tours/Ziplining", text: "Rainforest Ziplining", cruise: true, ShortDesc: "Race the howler monkeys through jungle canopies in a breathtaking experience." },
    { priceChild: 0, gridImages: importAll(require.context('./images/spiceFarm/grid', false, /\.(webp)$/)), slideImages: importAll(require.context('./images/spiceFarm', false, /\.(webp)$/)), whatToBring: nimLiWhatToBring, includes: nimLiIncludes, content: spiceFarmContent, price: 110,name: "spiceFarm", cardImage: spiceFarmImage, url: "/tours/SpiceFarm", text: "Spice Farm", cruise: true, ShortDesc: "A foodie's paradise where you will try the best of Belizian cuisine" },
    { priceChild: 0, gridImages: importAll(require.context('./images/lubaantun/grid', false, /\.(webp)$/)), slideImages: importAll(require.context('./images/lubaantun', false, /\.(webp)$/)), whatToBring: nimLiWhatToBring, includes: nimLiIncludes, content: lubaantunContent, price: 120,name: "lubaantun", cardImage: lubaantunImage, url: "/tours/Lubaantun", text: "Lubaantun Mayan Ruins", cruise: false, ShortDesc: "Explore the Mayan stronghold where the original crystal skull was found." },
    { priceChild: 0, gridImages: importAll(require.context('./images/riverTubing/grid', false, /\.(webp)$/)), slideImages: importAll(require.context('./images/riverTubing', false, /\.(webp)$/)), whatToBring: jungleTubingWhatToBring, includes: nimLiIncludes, content: jungleTubingContent, price: 95,name: "riverTubing", cardImage: riverTubingImage, url: "/tours/RiverTubing", text: "Jungle River Tubing", cruise: true, ShortDesc: "Dip your toes in as you float down the original jungle cruise ride." },
    { priceChild: 0, gridImages: importAll(require.context('./images/inlandBlueHole/grid', false, /\.(webp)$/)), slideImages: importAll(require.context('./images/inlandBlueHole', false, /\.(webp)$/)), whatToBring: inlandBlueHoleWhatToBring, includes: nimLiIncludes, content: inlandBlueHoleContent, price: 120,name: "inlandBlueHole", cardImage: inlandBlueHoleImage, ShortDesc: "", url: "/tours/InlandBlueHole", text: "Inland Blue Hole & Cave", ShortDesc:"Explore the rainforest and top it off with a  swim in a natural blue hole", cruise: false },
    { priceChild: 0, gridImages: importAll(require.context('./images/blueHole/grid', false, /\.(webp)$/)), slideImages: importAll(require.context('./images/blueHole', false, /\.(webp)$/)), whatToBring: blueHoleWhatToBring, includes: blueHoleIncludes, content: blueHoleContent,name: "blueHole", cardImage: blueHoleImage, ShortDesc: "Hope abord a private plane to fly your party over the great blue hole.", url: "/tours/BlueHoleFlyover", text: "Blue Hole Flyover", cruise: false },
    // { priceChild: 0, gridImages: importAll(require.context('./images/snorkel/grid', false, /\.(webp)$/)), slideImages: importAll(require.context('./images/snorkel', false, /\.(webp)$/)), whatToBring: snorkelingWhatToBring, includes: snorkelingIncludes, content: snorkelingContent, price: 675,name: "snorkel", cardImage: snorkelImage, ShortDesc: "", url: "/tours/Snorkel", text: "Caribbean Snorkeling", ShortDesc:"Snorkel through the Caribbean's beautiful Southwater Marine Reserve", cruise: false },
    // { priceChild: 0, gridImages: importAll(require.context('./images/fishing/grid', false, /\.(webp)$/)), slideImages: importAll(require.context('./images/fishing', false, /\.(webp)$/)), whatToBring: fishingWhatToBring, includes: fishingIncludes, content: fishingContent, price: 675,name: "fishing", cardImage: fishingImage, ShortDesc: "", url: "/tours/Fishing", text: "Caribbean Fishing", ShortDesc:"Your own private fishing boat to find the Caribbean catch of the day", cruise: false },
    { priceChild: 0, gridImages: importAll(require.context('./images/atm/grid', false, /\.(webp)$/)), slideImages: importAll(require.context('./images/atm', false, /\.(webp)$/)), whatToBring: nimLiWhatToBring, includes: nimLiIncludes, content: ATMContent, price: 365,name: "atm", cardImage: atmImage, ShortDesc: "Visit one of the world's ranked top 10 caves for this intense adventure tour.", url: "/tours/ATMCave", text: "ATM Cave Tour", cruise: false },
    { priceChild: 0, gridImages: importAll(require.context('./images/caveTubing/grid', false, /\.(webp)$/)), slideImages: importAll(require.context('./images/caveTubing', false, /\.(webp)$/)), whatToBring: nimLiWhatToBring, includes: nimLiIncludes, content: caveTubingContent, price: 175,name: "caveTubing", cardImage: caveTubingImage, ShortDesc: "Take a relaxing tube ride through the rainforest and ancient Mayan ritual caves", url: "/tours/CaveTubing", text: "Cave Tubing", cruise: false },
    { priceChild: 0, gridImages: importAll(require.context('./images/twinCities/grid', false, /\.(webp)$/)), slideImages: importAll(require.context('./images/twinCities', false, /\.(webp)$/)), whatToBring: nimLiWhatToBring, includes: nimLiIncludes, content: twinCitiesContent, price: 175,name: "twinCities", cardImage: twinCitiesImage, ShortDesc: "", url: "/tours/TwinCities", text: "Twin Cities Mayan Ruins", ShortDesc:"Visit the largest Mayan ruins in Belize in this two-for-one excursion", cruise: false },
    { priceChild: 0, gridImages: importAll(require.context('./images/zoo/grid', false, /\.(webp)$/)), slideImages: importAll(require.context('./images/zoo', false, /\.(webp)$/)), whatToBring: nimLiWhatToBring, includes: nimLiIncludes, content: belizeZooContent, price: 170,name: "zoo", cardImage: zooImage, ShortDesc: "", url: "/tours/BelizeZoo", text: "Belize Zoo", ShortDesc:"See all 5 of exotic cats native to Belize and many other exotic tropical species.", cruise: false },
    { priceChild: 0, gridImages: importAll(require.context('./images/yacht/grid', false, /\.(webp)$/)), slideImages: importAll(require.context('./images/yacht', false, /\.(webp)$/)), whatToBring: [], includes: [], content: yachtContent,name: "yacht", cardImage: yachtImage, ShortDesc: "", url: "/tours/Yacht", text: "Charter a Yacht", ShortDesc:"Take the keys for our yacht and charter your own voyage through the Caribbean", cruise: false }
];

tours.map(tour => {
    tour.slideImages["name"] = tour.name;
});

let bundles = [
    /*{
        name: "Cave Tubing and Altun Ha Mayan Ruins Bundle",
        tours: ["caveTubing", "altunHa"], 
        price: 90, 
        priceChild: 70,
        cruise: true, 
        cayes: true 
    }*/
]; 

function computeSavings(tourList, bundlePrice){
    let sumTourCosts = 0; 
    for(let i=0;i<tourList.length;++i){
        sumTourCosts+=tours.find(o => o.name===tourList[i]).price;
    }
    return sumTourCosts-bundlePrice; 
}
//Compute the savings for each bundle 
bundles.map(bundle =>(
    bundle.savings = computeSavings(bundle.tours, bundle.price)
));

function App() {
    let history = useHistory(); 
    let arr = []; 
    tours.map(tour => (arr.push(<Route path={"/tours/" + tour.name} exact component={() => <Home/>}/>))); 
    return (
        <div>
            <DesktopNavbar siteUrls={siteUrls} tours={tours} />
            <MobileNavbar siteUrls={siteUrls} tours={tours} />
            <Router>
                <Switch>
                    <Route path="/" exact component={() => <Home tours={tours}
                        bundles = {bundles} 
                        homeSlideImages={importAll(require.context('./images/home', false, /\.(webp)$/))}/>}/>
                    <Route path="/About" exact component={() => <About />} />
                    <Route path="/Contact" render={() => <Contact tours={tours} />} />
                    <Route path = "/Covid" render = {() => <Covid />}/>  
                    <Route path="/BookNow" render={() => <BookNow tours={tours} history={history} bundles = {bundles}/>} /> 
                    <Route path="/FAQ" render = {() => <FAQ/>}/>
                    <Route path="/Safety" render = {() => <Safety/>}/> 
                    <Route path="/ThankYou" render={() => <ThankYou />} />
                    <Route path="/ThankYouContact" render={() => <ThankYouContact />} />
                    <Route path="/tours/allTours" render={() => <AllTours tours={tours} />} /> 
                    <Route path="/tours/cruiseTours" render={() => <CruiseTours tours={tours} />} /> 
                    {
                        tours.map(tour => (
                            <Route path={tour.url} exact component={() => <TourBody tourInfo={tour} tours = {tours} bundles = {bundles}/>}/>
                            ))
                    }
                </Switch> 
            </Router>

            <Footer followUs={followUs} bottomBarGridItems={siteUrls} />
        </div>
    );
}

export default App;