import React from 'react';

class TourParagraph extends React.Component{
    
    constructor(props){
        super();
        this.firstBlock = props.text[0]; 

        this.state = {
            toggled: false
        };

        this.toggleClick = this.toggleClick.bind(this); 
    }

    toggleClick(){
        let toggle = this.state.toggled; 
        this.setState({toggled: !toggle});
    }

    render(){
        return(
            <div>
                <h3 className="tourTitle">{this.props.title}</h3>
                {this.props.text.length > 0 ? 
                    <div className="introText">
                        {this.props.text.map(textBlock =>
                        (<p className="homeTextBlock">{textBlock}</p>))}
                    </div>
                : null
                }
            </div>
        );
    }
}

export default TourParagraph;