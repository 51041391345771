import React from 'react';
import { useHistory } from 'react-router';

function SubmitButton({content, clickFunction, separateComponent}){
    let history = useHistory(); 
    return (
        <div className={content.length > 0 ? "submitButton red" : "submitButton"} 
             onClick={(event) =>
             { 
                let ret = clickFunction();
                if(ret)
                {
                    if(separateComponent)history.push(separateComponent);
                    else history.push("/ThankYou"); 
                }}}>
                {content.length>0 ? content : "Confirm"}
            </div>
        );
}

export default SubmitButton;