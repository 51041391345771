
function InstagramIcon() {
    return (
        <div className="facebookIcon">
            <div className="instagramInnerCircle">
            </div> 
            <div className="instagramDot">
            </div> 
        </div>
    );
}

export default InstagramIcon;