import FollowUsList from './FollowUsList.js'; 
import BottomBarGrid from './BottomBarGrid.js'; 

function BottomBarLogoMenu({ followUs, bottomBarGridItems }) {
    return (
        <div className="bottomBarLogoMenu">
            <FollowUsList followUs = { followUs }/> 
            <BottomBarGrid bottomBarGridItems={ bottomBarGridItems }/> 
        </div> 
    );
}

export default BottomBarLogoMenu;