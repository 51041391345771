import React from "react";
import HotelImg from '../Components/HotelImg.js'; 
import CruiseImg from '../Components/CruiseImg.js';
import TextField from './TextField.js';


class BookingTransportationGrid extends React.Component{
    
    constructor(props){
        super(); 

        this.state = {
            checked: null 
        }
    }

    processLocation(event){
        event.target.name === "hotel" ?  this.setState({checked: 1}) : 
        event.target.name === "cruise" ? this.setState({checked: 2}) :  
                                         this.setState({checked: 3}); 
    }

    render(){
        return(
        <div className = {this.state.checked === null ? "bookingTransportationGridContainer" : "bookingTransportationGridContainer long"}>                         
            <div className = "bookingTransportationGrid">
                <div className = {this.state.checked === 1 ? "bookingTransportationOption blue" : 
                                  this.state.checked === null ? "bookingTransportationOption"
                                  : "bookingTransportationOption red"} onClick = {(event) =>
                    {
                        if(this.state.checked === 1) 
                        {
                            this.setState({checked: null});
                            this.props.hotelCallback("", false); 
                        }
                        else 
                        {
                            this.setState({checked: 1});
                            this.props.hotelCallback("", true);
                        }
                        this.props.callbackFunction(this.state.checked === 1 ? null : 1);
                    }}>
                    <label className="checkboxContainer" >
                        <HotelImg small = {true}/> 
                        <div className = {this.state.checked === 1 ? "checked" : ""} type="checkbox" name="hotel"/>
                        <span class= "checkmark" ></span>
                    </label> 
                    <div className = "bookingTransportationTitle">Staying at Hotel in Placencia Area</div>
                </div>
                <div className = {this.state.checked === 2 ? "bookingTransportationOption blue" :                                   this.state.checked === null ? "bookingTransportationOption"
                                  : "bookingTransportationOption red"} onClick = {(event) => 
                    {
                        if(this.state.checked === 2)
                        {
                            this.setState({checked: null});
                            this.props.hotelCallback("", false); 
                        }
                        else 
                        {
                            this.setState({checked: 2});
                            this.props.hotelCallback("Placencia Docks", true)
                        }
                        this.props.callbackFunction(this.state.checked === 2 ? null : 2);}}>
                    <label className="checkboxContainer" >
                        <CruiseImg small = {true}/>
                        <div className = {this.state.checked === 2 ? "checked" : ""} type="checkbox" name="cruise"/>
                        <span class= "checkmark" ></span>
                    </label> 
                    <div className = "bookingTransportationTitle">Taking Harvest Caye ferry to Placencia Docks</div>
                </div>      
            </div>
                {this.state.checked === 1 ? 
                    <TextField for="hotelName"
                    fullWidth = {true}
                    criteriaFunction={(x)=>{return x.trim().length > 5}}
                    placeholder="Placencia Hotel"
                    labelText="Hotel Name/Address"
                    changeFunction={this.props.hotelCallback}/> : null}
        </div> 
        )
    }
}
export default BookingTransportationGrid; 