import React from 'react';

import '../App.css';
import ImageSlideDeck from '../Components/ImageSlideDeck.js'; 
import BookNow from './BookNow.js';
import CruiseImg from '../Components/CruiseImg.js';
import HotelImg from '../Components/HotelImg.js'; 
import TransportationOptions from '../Components/TransportationOptions.js'; 
import TourGrid from '../Components/TourGrid.js'; 
import MoreToursBtn from '../Components/MoreToursBtn.js'; 
import BundleTours from '../Components/BundleTours.js'; 

const transportationOptions = [
    {
        url: "/tours/AllTours",
        title: "Cruise Excursions",
        imgComponent: CruiseImg,
        description: [
            "Guests arriving from Norweigan Cruise Lines will be docking at the private island harvest Caye just a 20 minute boat ride offshore from the Placencia Peninsula.",
            "Catch one of the three morning ferries to Placencia Docks, where your guides will be waiting for a sign to take you to your adventure!",
            "You will have plenty of time to see the sites before we get you back to the Placencia Docks to make the ferry back to Harvest Caye"
        ]
    },
    {
        url: "/tours/AllTours",
        title: "Placencia Area Hotel Excursions",
        imgComponent: HotelImg,
        description: [
            "Staying in Placencia? Let us know where, and we can come and get you for a day filled with as many adventures as you could possibly want.",
            "There will be as much time as you need to relax, and stop at whatever sites and attractions take your fancy along the journey!",
            "We will take you to anything that crosses your mind, and drive you back to where you are staying when your journey is over."
        ]
    }
]; 

const introText = [
    "Placencia Action Tours is your number one choice in tour operations when visiting the Placencia peninsula.",
    "We offer tours in the southern region of Belize and with sister companies all across the country we are the most viable choice for reaching any and all of your vacation goals while visiting Beautiful Belize."
];

const bundleText = ["Extend your tour adventure and get fantastic savings by adding on some additional tours! Your professional guides will take you to multiple locations in the day so that you can make the most out of your time here.",
"Be sure to filter on where you are residing so that we can make sure you have as much time as possible to see the wonders of Belize."
]; 

class Home extends React.Component {

    constructor(props) {
        super();
        this.homeSlideImages = props.homeSlideImages;
        this.homeSlideImages["name"] = "home"; 
        props.tours.sort((a, b) => { return a.name === "waterfalls" ? -1 : b.name === "waterfalls" ? 1 : a.name === b.name ? 0 : a.name < b.name ? -1 : 1 }); 
        this.allTours = props.tours; 
        this.state = {
            tours: props.tours.slice(0, 3),
            bookingToggled: false,
            bookingBundle: null,
            windowWidth: window.innerWidth
        }
        this.addTours = this.addTours.bind(this); 
        this.toggleBooking = this.toggleBooking.bind(this); 
        this.firstPicMobile = props.homeSlideImages["home5.webp"];
        this.firstPicDesktop = props.homeSlideImages["home1.webp"];
    }

    swapPicsMobile(){
        this.homeSlideImages["home1.webp"] = this.firstPicMobile; 
        this.homeSlideImages["home5.webp"] = this.firstPicDesktop; 
    }

    swapPicsDesktop(){
        this.homeSlideImages["home1.webp"] = this.firstPicDesktop;
        this.homeSlideImages["home5.webp"] = this.firstPicMobile; 
    }


    componentDidMount() {
    }

    componentWillUnmount() {
       
    }

    toggleBooking(bundle){
        let temp = this.state.bookingToggled;
        this.setState({bookingToggled: !temp});
        this.setState({bookingBundle: bundle});
        if(!temp)
            window.scrollTo(0, 0); 
    }

    addTours() {
        if (this.state.tours === this.allTours) this.setState({tours: this.allTours.slice(0,3)})
        else this.setState({ tours: this.allTours }); 
    }


    render() {
        return (
            <div className = {!this.state.bookingToggled ? "homeContainer" : "homeContainer toggled"}>
                {this.state.bookingToggled ?
                    <BookNow selectedTours = {this.state.bookingBundle}
                     closeFunction = {this.toggleBooking}
                     iframe = {true}
                     bundles = {this.props.bundles}
                     tours = {this.props.tours}/>
                : null}
                <ImageSlideDeck images={this.homeSlideImages}
                                alts={[]}/>
                <div className="homeBodyContainer">
                    <h1 className="homeTitle">Southern Belize Group Tours</h1>
                    <div className="introText">{introText.map(textBlock =>
                        (<p className="homeTextBlock">{textBlock}</p>))}
                    </div>
                    {!this.state.bookingToggled ?
                    <div>
                        <TransportationOptions transportationOptions={transportationOptions} />
                        <h3 className="tourTitle">Popular Tours</h3>
                        <TourGrid tours={this.state.tours}
                        bookTourFunction = {this.toggleBooking}
                        reducedHeight={this.state.tours.length <= 3}/>
                        <MoreToursBtn clickFunction={this.addTours}/>
                        {this.props.bundles.length  > 0 ?
                            <BundleTours clickBundleFunction = {this.toggleBooking}
                                         tours = {this.allTours}
                                         bundles = {this.props.bundles}
                                         bundleText = {bundleText}
                                         bundleTitle = "Bundle Tours and Save!"/> 
                        : null}
                    </div> : null }
                </div>
            </div>
            );
    }
}

export default Home;