import React from 'react';

import LogisticsGridItem from './LogisticsGridItem.js'; 


class LogisticsGrid extends React.Component {

    constructor(props) {
        super();
    }



    render() {

        return (
            <div className="logisticsGrid">
                {this.props.includes && this.props.includes.length > 0 ? 
                    <LogisticsGridItem content={this.props.includes} title="This Tour Includes" /> 
                    : <></>
                }
                {this.props.whatToBring && this.props.whatToBring.length > 0 ? 
                    <LogisticsGridItem content={this.props.whatToBring} title="What to Bring"/> 
                    : <></>
                }
            </div> 
        );
    }
}



export default LogisticsGrid;