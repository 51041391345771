import React from 'react';

// MUI
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Custom Components
import '../Styles/Tour.css';
import Title from './Title.js'; 
import BookNow from './BookNow.js'; 
import BundleTours from './BundleTours.js'; 
import LongDescription from './LongDescription.js'; 
import LogisticsGrid from './LogisticsGrid.js'; 
import MoreToursBtn from './MoreToursBtn.js'; 
import PricingBox from './PricingBox.js'; 
import ImageSlideDeck from './ImageSlideDeck.js'; 
import TourGrid from './TourGrid.js';

class TourBody extends React.Component {

    constructor(props) {
        super();
        //Sort all tours alphabetically but with preference to always show caveTubing first. 
        this.allTours = props.tours.sort((a, b) => {return a.name === "waterfalls" ? -1 : b.name === "waterfalls" ? 1 : a.name === b.name ? 0 : a.name < b.name ? -1 : 1 });
        //Remove bundles that this specific tour isn't included in
        this.bundles = props.bundles.filter(bundle => bundle.tours.includes(props.tourInfo.name)); 
        //Sort the bundle tour cards to prioritize this tour name coming first, the rest alphabetically 
        this.bundles.map(bundle => bundle.tours.sort((a, b) => {return a === props.tourInfo.name ? -1 :
                                                                       b === props.tourInfo.name ? 1 : 
                                                                       a < b ? -1 : 
                                                                       a > b ? 1: 
                                                                       0})); 
        this.state = {
            tours: this.allTours.filter(tour => !(tour.name === props.tourInfo.name)).slice(0, 3),
            bookingToggled: false,
            bookingBundle: null,
            gridToggled: false
        }
        this.toggleBooking = this.toggleBooking.bind(this); 
        this.addTours = this.addTours.bind(this); 
        this.bundleText = ["Extend your tour adventure and get fantastic savings by adding on some additional tours! Your professional guides will take you to multiple locations in the day so that you can make the most out of your time here.",
            "Be sure to filter on where you are residing so that we can make sure you have as much time as possible to see the wonders of Belize."
        ];
    }

    toggleBooking(bundle){
        let temp = this.state.bookingToggled; 
        this.setState({bookingToggled: !temp});
        this.setState({bookingBundle: bundle}); 
        if(!temp)
            window.scrollTo(0, 0); 
    }

    addTours() {
        if (this.state.tours === this.allTours) this.setState({ tours: this.allTours.slice(0, 3) })
        else this.setState({ tours: this.allTours });
    }

    render() {
        return (
            <div className = {!this.state.bookingToggled ? "homeContainer" : "homeContainer toggled"}>
                {this.state.bookingToggled ? <BookNow selectedTours = {this.state.bookingBundle} closeFunction = {this.toggleBooking} iframe = {true} bundles = {this.props.bundles} tours = {this.props.tours}/> : null} 
                <ImageSlideDeck images={this.props.tourInfo.slideImages} />
                {!this.state.bookingToggled ? 
                    <div className="tourBody"> 
                        <Title text={this.props.tourInfo.text} /> 
                        <LongDescription content={this.props.tourInfo.content} /> 
                        <LogisticsGrid includes={this.props.tourInfo.includes} whatToBring={this.props.tourInfo.whatToBring}/> 
                        <PricingBox bookTourFunction = {this.toggleBooking} name = {this.props.tourInfo.name} cruise={this.props.tourInfo.cruise} price={this.props.tourInfo.price} priceChild={this.props.tourInfo.priceChild}/> 
                        {this.bundles.length > 0 ? 
                            <BundleTours tours = {this.allTours}
                                        clickBundleFunction = {this.toggleBooking}
                                        bundles = {this.bundles}
                                        bundleText = {this.bundleText}
                                        bundleTitle = "Save on Bundles Available with this Tour"/>
                        : null}

                        {this.props.tourInfo.gridImages && Object.entries(this.props.tourInfo.gridImages).length > 1 ?
                        <div>
                          <Button variant="contained"
                            onClick={() => {const tempToggled = this.state.gridToggled; this.setState({gridToggled: !tempToggled})}}>
                            {this.state.gridToggled ? "Hide" : "Show"} Image Grid for {this.props.tourInfo.text}
                            <ArrowDropDownIcon
                            className={this.state.gridToggled ? "rotateButton rotated" : "rotateButton"}
                            />
                          </Button>
                          {
                          this.state.gridToggled ?
                          <ImageList variant="masonry" cols={3} gap={8}>
                              {Object.entries(this.props.tourInfo.gridImages).map(([key, value]) => {
                                if (value instanceof Object && 'default' in value) {
                                  return <ImageListItem key={key}>
                                            <img src={value.default} alt={key} key={key} loading="lazy"></img>
                                      </ImageListItem>;
                                } else {
                                  return null;
                                }
                              })}
                          </ImageList>
                          : <></>
                          }
                        </div> : <></>}

                        <h3 className="otherToursTitle">Other Popular Tours</h3> 
                        <TourGrid tours={this.state.tours}
                                bookTourFunction = {this.toggleBooking}
                                omit={this.props.tourInfo.name} /> 
                        <MoreToursBtn clickFunction={this.addTours} />
                    </div>
                :null}


              {/* {Object.entries(this.props.tourInfo.gridImages).map(([key, value]) => {
                if (value instanceof Object && 'default' in value) {
                  return <img src={value.default} alt={key} key={key} />;
                } else {
                  return null;
                }
              })} */}
            </div>
            );
    }
}

export default TourBody;